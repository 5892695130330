import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
//import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

import MKTypography from "components/MKTypography";

import logoCT from "assets/images/favicon.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "zerados.com.br",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/zerados/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/zerados",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/zerados",
    },
  ],
  menus: [
    {
      name: "Empresa",
      items: [
        { name: "sobre nós", href: "/pages/landing-pages/about-us" },
        { name: "cvrtec", href: "http://www.cvrtec.com.br" },
      ],
    },
    {
      name: "Anuncios",
      items: [
        { name: "anúncios", href: "/pages/anuncios/" },
        { name: "novo anúncio", href: "/pges/painel/" },
      ],
    },
    {
      name: "Suporte",
      items: [
        { name: "contato", href: "/pages/landing-pages/contact-us#" },
        // { name: "abusos", href: "#" },
      ],
    },
    {
      name: "Legal",
      items: [
        { 
          name: "política de privacidade", 
          href: "/pages/landing-pages/politics"
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Todos direitos reservados. Copyright &copy; {date} &nbsp;
      <MKTypography
        component="a"
        href="https://www.zerados.com.br"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        zerados.com.br
      </MKTypography>
      .
    </MKTypography>
  ),
};
