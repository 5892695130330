import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";

function PerfilCard({ titulo, nome, link, icone, email, perfil, plano }) {
  let direction = "center";
  return (
    <Card sx={{ mt: -1 }}>
      <Grid container>
        <Grid item xs={6} md={6} lg={2} sx={{ mt: "auto" }} >
          <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
            <MKTypography 
              variant="h1" 
              display="block"
              color="primary" 
              ml={1}
            >
              <Icon>{icone}</Icon>
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }}>
          <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
            <MKTypography variant="h5">{titulo}</MKTypography>
            <MKTypography variant="body2" color="text">
              <i>Nome:</i> <strong>{nome}</strong>  <i>Perfil:</i> <strong>{perfil}</strong>
            </MKTypography>
            <MKTypography variant="body2" color="text">
            <i>e-mail:</i> <strong>{email}</strong>  <i>Plano:</i> <strong>{plano}</strong> 
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <a href={link}>Acessar</a>
            </MKTypography>
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

PerfilCard.propTypes = {
  titulo: PropTypes.string.isRequired,
  nome: PropTypes.string,
  link: PropTypes.string.isRequired,
  icone: PropTypes.string.isRequired,
  email: PropTypes.string,
  perfil: PropTypes.string,
  plano: PropTypes.string,
};

export default PerfilCard;
