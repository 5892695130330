import { useEffect, useState } from "react";
import BaseLayout from "layouts/sections/components/BaseLayout";
import AnunciosBlocks from "pages/Anuncios/sections/AnunciosBlocks";
//import useQuery from "./components/useQuery";

function Anuncios() {
  return (
    <BaseLayout
      title="Anúncios"
      breadcrumb={[{ label: "home", route: "/" }, { label: "Anúncios" }]}
    >     
      <AnunciosBlocks />
    </BaseLayout>
  );
}

export default Anuncios;
