import * as React from 'react';
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Link } from 'react-router-dom';
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import Card from "@mui/material/Card";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { Edit } from '@mui/icons-material';

//import { Navigate } from "react";
//import Modal from "@mui/material/Modal";

import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKBadge from 'components/MKBadge';

import { getAnunciosPorUsuario, cancelaAnuncio, revalidaAnuncio } from "services/AnuncioService"
import BaseLayout from 'layouts/sections/components/BaseLayout';
import moment from 'moment';
import EnhancedTable from "pages/Cadastro/Anuncio/ListaAnuncios/Section/enhancedTable";
import MKPagination from "components/MKPagination";
import { AccessAlarm, Newspaper, ViewHeadline, Ballot } from '@mui/icons-material';

function ListaAnuncios() {
    const [message, setMessage] = useState("");
    const [messageEmail, setMessageEmail] = useState("");
    const [ msgErroCampo, setMsgErroCampo ] = useState("");
    const [dataInicio, setDataInicio] = useState("");
    const [dataFim, setDataFim] = useState("");
    const [palavras, setPalavras] = useState("");
    const [placa, setPlaca] = useState("");
    const [ usrLogado, setUsrLogado ] = useState({});
    const [ meusAnuncios, setMeusAnuncios ] = useState([]);
    const [ numPag, setNumPag ] = useState(0);

    const getAnunciosLista = async () => {
      //setLoading(true);
      setMeusAnuncios([]);
      getAnunciosPorUsuario(palavras, placa, parseInt(usrLogado.idUsuario), dataInicio, dataFim)
      .then(result => {       
        //let resJson = await response.json();  
        if (result) {
          //setLoading(false);
          setMeusAnuncios(result);
          setNumPag(result.length);  
          setMessage("Lista de anúncios cadastrados por você!.");
          //navigate("/login");
        }
        else
        {
          setMessage("Não foi possível oter anúncios para este usuário.");
          //setLoading(false);
        }
      })
      .catch(); 
    }

    let handleSubmit = async (e) => { 
      setMessage("");       
      e.preventDefault();
      await getAnunciosLista();
    };

    useEffect(() => {
      moment.locale('pt-br');
      //setDataInicio(moment().add(-5, 'days').format('yyyy-MM-DD'));   
      setDataInicio(moment().startOf('month').format('yyyy-MM-DD'));
      setDataFim(moment().format('yyyy-MM-DD'));
      setUsrLogado(JSON.parse(localStorage.getItem("usuarioLogado")));
      // console.log(usrLogado);
      // setTimeout(() => {
      //   getAnunciosLista();
      // }, 5000);
      
    }, []);

  
    // const handleChange = prop => event => {
    //   setValues({ ...values, [prop]: event.target.value });
    // };  

    let limparDados = () => {
      setPalavras("");
      setPlaca("");
      setDataInicio("");
      setDataFim("");
    }

    let cancelarAnuncio = (idAnuncio) => {
      if(idAnuncio){
        cancelaAnuncio(idAnuncio);
        getAnunciosLista();
      }
    }

    let revalidarAnuncio = (idAnuncio) => {
      if(idAnuncio){
        revalidaAnuncio(idAnuncio);
        getAnunciosLista();
      }
    }

    return (
      <>
      <BaseLayout
      title="Anúncios"
      breadcrumb={[{ label: "home", route: "/" }, { label: "Anúncios" }]}
      >
      <Grid alignItems="top">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          ml={{ xs: "auto", lg: 2 }}
          mr={{ xs: "auto", lg: 2 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="md"
            borderColor="dark"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 4, sm: 4, md: 4 }}
            mb={{ xs: 1, sm: 1, md: 1 }}
            mx={3}
            sx={{ backgroundColor: `rgb(241,241,241)` }}
          >
            <MKBox
              variant="contained"
              bgColor="dark"
              coloredShadow="info"
              borderRadius="xs"
              p={2}
              mx={0}
              mt={0}
              textAlign="center"
            >
              <MKTypography variant="h3" color="text">
                Meus anúncios cadastrados
              </MKTypography>
              <Grid container spacing={3} direction="row" justifyContent="center">
                <Grid item xs={12} md={2}>
                  <MKTypography variant="caption" color="text">
                    <Link to="/pages/painel" className="nav-link" title="Meu Painel"><Ballot  color="white"  fontSize="large"/></Link>
                  </MKTypography>
                </Grid>
              </Grid>
            </MKBox>

            <MKBox p={2}>
              <form onSubmit={handleSubmit}>
              <MKBox width="100%">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <MKInput
                            type="text"
                            variant="standard"
                            label="palavras"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={palavras} 
                            onChange={(e) => setPalavras(e.target.value)}
                            //onFocus={ focusHandler}
                            //onBlur={ blurHandler }
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <MKInput
                            type="text"
                            variant="standard"
                            label="placa"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={placa} 
                            onChange={(e) => setPlaca(e.target.value)} 
                            min={8}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <MKInput
                            type="date"
                            variant="standard"
                            label="início"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={dataInicio} 
                            onChange={(e) => setDataInicio(e.target.value)} 
                            min={8}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <MKInput
                            type="date"
                            variant="standard"
                            label="fim"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={dataFim} 
                            onChange={(e) => setDataFim(e.target.value)} 
                            min={8}
                        />
                    </Grid>
                
                    <Grid item xs={12} md={3}>
                        <MKButton type="submit" variant="gradient" color="primary">
                            Pesquisar
                        </MKButton>&nbsp;
                        <MKButton type="button" variant="gradient" color="success" onClick={ limparDados }>
                            Limpar
                        </MKButton>
                    </Grid>
                </Grid>
                </MKBox>
              </form>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>

      <Grid container spancing={3}>
      <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          ml={{ xs: "auto", lg: 2 }}
          mr={{ xs: "auto", lg: 2 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="md"
            borderColor="dark"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 1, sm: 1, md: 1 }}
            mb={{ xs: 6, sm: 9, md: 6 }}
            mx={3}
            sx={{ backgroundColor: `rgb(241,241,241)` }}
          >
          <Grid container ml={2} mb={3} item> 
          <table>
            <thead>
              <tr>
                <td width="30">#</td>
                <td width="100">Editar</td>
                <td width="120">Ação</td>
                <td width="150">Publicado</td>
                <td width="150">Validade</td>
                <td width="150">Marca</td>
                <td width="150">Modelo</td>
                <td width="100">Placa</td>
              </tr>
            </thead>
            
            <tbody>
            {
              meusAnuncios.map((anuncio, index) => {
                return(
                  <>
                  <tr key={index}>
                    <td>
                      <MKTypography variant="button" color="text">
                        {anuncio.idAnuncio}
                      </MKTypography>
                    </td>
                    <td>                    
                      <MKTypography variant="button" color="text">
                        <Link to={`/cadastro/editaanuncio?id=${anuncio.idAnuncio}`} title="Editar"><Edit  color="primary" fontSize="small" /></Link>
                      </MKTypography>
                    </td>
                    <td> 
                    <Tooltip title="Invalida o Anúncio" placement="top">

                    {
                      new Date(anuncio.dataValidade).toLocaleDateString() > new Date().toLocaleDateString() ? 
                        <MKBadge id={anuncio.idAnuncio} value={anuncio.idAnuncio} variant="contained" badgeContent="cancelar" color="error" 
                          onClick={(e) => cancelarAnuncio(anuncio.idAnuncio)} container />                        
                        :
                        <MKBadge id={anuncio.idAnuncio} value={anuncio.idAnuncio} variant="contained" badgeContent="revalidar" color="warning" 
                          onClick={(e) => revalidarAnuncio(anuncio.idAnuncio)} container />
                        }
                    </Tooltip>
                    </td>
                    <td> 
                    {/* {moment(anuncio.dataPublicacao).format('DD/MM/yyyy')} */}
                      <MKTypography variant="caption" color="text">{anuncio.dataPublicacao}</MKTypography>
                    </td>
                    <td xs={12} md={1} ml="2"> 
                    {new Date(anuncio.dataValidade) < new Date() ? 
                      <MKTypography variant="caption" color="danger">{anuncio.dataValidade}</MKTypography>
                      :
                      <MKTypography variant="caption" color="text">{anuncio.dataValidade}  </MKTypography>
                      }
                    </td>
                    <td xs={12} md={2} ml="2"> 
                      <MKTypography variant="caption" color="text">                     
                        {anuncio.nomeMarca} 
                      </MKTypography>
                    </td>
                    <td xs={12} md={4} ml="2"> 
                      <MKTypography variant="caption" color="text">                     
                        {anuncio.nomeModelo}
                      </MKTypography>
                    </td>
                    <td xs={12} md={2} ml="2"> 
                      <MKTypography variant="caption" color="text">                     
                        {anuncio.placa}
                      </MKTypography>
                    </td>
                  </tr>
                  </>
                )}
              )
            }
            </tbody>
            </table>
          </Grid>  
          {/* <Grid container mb={5} item justifyContent="center" height="100%">
            <MKPagination>
              <MKPagination item>
                <Icon>keyboard_arrow_left</Icon>
              </MKPagination>
              <MKPagination item active>
                1
              </MKPagination>
              <MKPagination item>2</MKPagination>
              <MKPagination item>3</MKPagination>
              <MKPagination item>4</MKPagination>
              <MKPagination item>5</MKPagination>
              <MKPagination item>
                <Icon>keyboard_arrow_right</Icon>
              </MKPagination>
            </MKPagination>
          </Grid> */}
          </MKBox>
          
          </Grid>
      </Grid>

  </BaseLayout>
    </>
    );

}

export default ListaAnuncios;
