import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container item xs={12} lg={12} justifyContent="center" mx="auto" mb={5}>
          <MKTypography
            variant="body2"
            color="text"
            textAlign="justify"
            px={{ xs: 6, lg: 12 }}
            mt={1}
          >
            Os dados que solicitamos são utilizados para oferecer o melhor serviço para você e para
            permitir uma comunicação sem falhas.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="LGPD"
                    description="Estamos em conformidade com as disposições da LGPD brasileira"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Pagamento"
                    description="Não solicitamos dados para pagamentos, como números de cartões de crédito"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Propostas"
                    description="As propostas de negócio feitas pelo zerados.com.br são encaminhadas diretamente ao proprietário"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Veículos"
                    description="Os dados dos veúlos aqui informados são de inteira responsabilidade dos anunciantes. azerados.com.br não garante a procedência dos mesmos"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Consulte nosso anúncios"
              description="Encontre o veículo dos seus sonhos"
              action={{
                type: "internal",
                route: "pages/anuncios",
                color: "info",
                label: "Anúncios",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
