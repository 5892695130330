import http from '../utils/http';
import http2 from '../utils/http';

const context = "anonimo";

export const sendMessage = (formData) => {
  return http.post(`/${context}/sendMessage`, formData).then(result => result.data);
}

export const getCepAddress = (cep) =>  {
    return http2.get(`https://viacep.com.br/ws/${cep}/json`).then(result => result.data);
}

