//import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import PriceInformation from "./PriceInformation";

function PriceBlocks() {
  return (
    <MKBox component="section" my={1} py={1}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Preços em Reais"
            container
            sx={{ mb: 2 }}
          />
          {/* <MKTypography variant="h2" fontWeight="bold">
            zerados.com.br
          </MKTypography> */}
          <MKTypography variant="body1" color="text">
            - Encontre o pacote que melhor se encaixa ao seu negócio -
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: 6 }}>
        <PriceInformation />
      </Container>
    </MKBox>
  );
}

export default PriceBlocks;
