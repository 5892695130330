//import { Usuario } from '../models/Usuario.model';
//import { PwdChange } from '../models/PwdChange.model';
import http from '../utils/http';
  
  const context = "anonimo";

  export const autenticarZerados = (token) => {
    return http.post(`/token`, {token}).then(result => result.data)
  }
  
  export const solicitaRecuperaSenha = (email) => {
    return http.post(`/${context}/esqueciMinhaSenha?email=${email}` ).then(result => result.data);
    //return http.post<boolean>(`/${context}/esqueciMinhaSenha`, {email} ).then(result => result.data);
  }

  export const alteraSenha = (pwd) => {
    return http.post(`/${context}/alteraSenhaPorToken`, pwd).then(result => result.data);
  }
  