/*export const GetMaxLengthHint = (control: any, max: number): string {
    const value = <string>control.value;
    if (max > 0) { return `${!!value ? value.length : 0} / ${max}`; } else { return ''; }
}

export const Compare = (a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}*/

export const ConverterParaMoeda = (num: number) =>  {
    const result = parseFloat((Math.round(num * 100) / 100).toFixed(2));
    return result;
}

export const FormatNumber = (num: number) => {
    return (
        num
            .toFixed(2) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    );
}

export const ForcaSenha = (senha: string) => {
    let forca = 0;

    const regLetrasMa = /[A-Z]/;
    const regLetrasMi = /[a-z]/;
    const regNumero = /[0-9]/;
    const regEspecial = /[!@#$%&*?]/;

    let tam = false;
    let tamM = false;
    let letrasMa = false;
    let letrasMi = false;
    let numero = false;
    let especial = false;

    if (senha.length >= 8) { tam = true; }
    if (senha.length >= 10) { tamM = true; }
    if (regLetrasMa.exec(senha)) { letrasMa = true; }
    if (regLetrasMi.exec(senha)) { letrasMi = true; }
    if (regNumero.exec(senha)) { numero = true; }
    if (regEspecial.exec(senha)) { especial = true; }

    if (tam) { forca += 20; }
    if (tamM) { forca += 10; }
    if (letrasMa) { forca += 10; }
    if (letrasMi) { forca += 10; }
    if (letrasMa && letrasMi && numero && especial) { forca += 30; }
    if (numero) { forca += 10; }
    if (especial) { forca += 10; }

    return forca;
}