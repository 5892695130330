import http from '../utils/http';

  const context = "anuncios";
  
  export const getDadosAnuncio = () => {
    return http.get(`/${context}/listaDadosNovoAnuncio`).then(result => result.data)
  }

  export const getAnuncios = (idColaborador) => {
    return http.get(`/${context}/getAnuncios/${idColaborador}`).then(result => result.data)
  }

  export const getAnunciosPorUsuario = (palavras, placa, idUsuario, dataInicio, dataFim) => {
    return http.get(`/${context}/getAnunciosPorUsuario?palavras=${palavras}&placa=${placa}&idUsuario=${idUsuario}&dataInicio=${dataInicio}&dataFim=${dataFim}`).then(result => result.data)
  }

  export const getAnuncioPorId = (idAnuncio) => {
    return http.get(`/${context}/getAnuncioPorId/${idAnuncio}`).then(result => result.data)
  }

  export const getCaracteristicasModelo = (idModeloMarca) => {
    return http.get(`/${context}/getCaracteristicasModelo/${idModeloMarca}`).then(result => result.data)
  }

  export const gravaNovoAnuncio = (anuncio) => {
    return http.post(`/${context}/novoAnuncio`, anuncio).then(result => result.data)
  }

  export const atualizaAnuncio = (anuncio) => {
    return http.put(`/${context}/atualizaAnuncio`, anuncio).then(result => result.data)
  }

  export const cancelaAnuncio = (idAnuncio) => {
    return http.get(`/${context}/cancelaAnuncio/${idAnuncio}`).then(result => result.data)
  }

  export const revalidaAnuncio = (idAnuncio) => {
    return http.get(`/${context}/revalidaAnuncio/${idAnuncio}`).then(result => result.data)
  }

  export const uploadImagem = (idAnuncio, imagem) => {
    return http.post(`/${context}/uploadFile/`+idAnuncio, imagem ).then(result => result.data)
  }

  export const excluirImagem = () => {
    return http.delete(`/${context}/excluirArquivo`).then(result => result.data)
  }
  
  export const ListarImagens = () => {
    return http.get(`/${context}/listarArquivos`).then(result => result.data)
  }

