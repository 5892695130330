import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
//import MuiLink from "@mui/material/Link";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKProgress from "components/MKProgress";

import BaseLayout from "layouts/sections/components/BaseLayout";

import bgImage from "assets/images/zerados002pb.jpg";
import { ForcaSenha } from "../../../services/UtilService";
import { alteraSenha } from "../../../services/LoginService";

function RecuperaSenha(){

    const navigate = useNavigate ();
    const [ message, setMessage] = useState("");
    const [ forcaPwd, setForcaPwd] = useState(0);
    const [ pwdValida, setPwdValida] = useState(false);
    const [messageSenha, setMessageSenha] = useState("");
    const [ cor, setCor ] = useState("light");
    const [token, setToken] = useState("");
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirma, setPasswordConfirma] = useState("");
    const [ pwdChange, setPwdChange ] = useState({});

    useEffect(() => {
      //const { id } = useParams();
      let idtk = (new URLSearchParams(window.location.search)).get("idtk");
      let lg = (new URLSearchParams(window.location.search)).get("lg");
      if(!idtk)idtk="0";
      if(!lg)lg="0";

      setToken(idtk);
      setLogin(lg);
    }, [])
      
    let handleSubmit = async (e) => { 
        setMessage("");  

        if(!pwdValida){
          setPwdValida(true);
          setMessage("Sua senha não atende aos requisitos ...");  
          return;
        }

        if(password != passwordConfirma) {
          setPwdValida(true);
          setMessage("As senhas não conferem...");  
          return;
        }     
        e.preventDefault();
        try {
          let modelo = montaPasswordModel();
          //setPwdChange(modelo);
          alteraSenha(modelo)
          .then(result => {
            if (result) {
              setMessage(result);
              //navigate("/login");
            }
            else
            {
              setMessage("Não foi alterar sua senha.");
              //setLoading(false);
            }
          })
        } catch (err) {
          console.log(err);
          setMessage("Algo ruim aconteceu!!! :-(" + err);
        }
    };

    let montaPasswordModel =() => {
        pwdChange.login = login;
        pwdChange.password = password;
        pwdChange.token = token;
        return pwdChange;
      }

    let limparDados = ()=>{
        setPassword("");
        setPasswordConfirma("");
        setMessage("");
        setMessageSenha("");
        setCor("");
        setForcaPwd(0);
      }

    const validarForcaSenha = (pwd) => {
        if (pwd != null) {
          const fSenha = ForcaSenha(pwd);
          const bufferValue = fSenha;
          const textoForcaSenha = '';  
          if (fSenha <= 40) {
            setMessage('A senha não atende os requisitos necessários!');
            setCor('error');
            setPwdValida(false);          
            setForcaPwd(fSenha);
          }    
          if (fSenha > 40 && fSenha <= 70) {
            
            setMessage('A senha ainda NÃO atende aos requisitos necessários!');
            setPwdValida(false);
            setCor('warning');
            setForcaPwd(fSenha);
          }    
          if (fSenha > 70 && fSenha <= 90) {
            setMessage('Sua senha é fraca, mas ATENDE aos requisitos necessários!');
            setPwdValida(true);
            setCor('success');
            setForcaPwd(fSenha);
          }    
          if (fSenha > 90) {
            setMessage('Senha forte! ATENDE aos requisitos necessários!');
            setPwdValida(true);
            setCor('success');
            setForcaPwd(100);
          }
        } else {
          setMessage('');
          setCor('');
        }  
      }
  
      const handlePasswordChange = (e) =>{
        setPassword(e);
        validarForcaSenha(e);
      }

      const handlePasswordConfirma = (e) =>{
        setPasswordConfirma(e);
        if(password == passwordConfirma){
          setMessage("");
        }
      }

      let limpaMessage = ()=>{
        setMessage("");
      }

      let comparaSenhas =() => {
        setMessageSenha("");
        if(password !== passwordConfirma )
        {
          setMessageSenha("As senhas não conferem !!!");
          setPasswordConfirma("");
        }
        else
        {
          setMessageSenha("");
        }
      }

      const focusSenhasHandler = () => {
        setMessageSenha("");
      }

    return (
        <>
        <BaseLayout
            title=""
            breadcrumb={[{ label: "home", route: "/" }, { label: "Minha Senha" }]}
        >
        <Grid container spacing={3} alignItems="top">
          <Grid item           
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            ml={{ xs: "auto", lg: 16 }}
            mr={{ xs: "auto", lg: 2 }}
            mt={{ xs: 4, sm: 4, md: 4 }}
            mb={{ xs: 6, sm: 9, md: 6 }}
          >
            <img src={bgImage} alt="zerados" title="zerados" mt={6} width={450} className="rounded img-fluid" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            ml={{ xs: "auto", lg: 2 }}
            mr={{ xs: "auto", lg: 2 }}
          >
          <MKBox
            bgColor="white"
            borderRadius="md"
            borderColor="dark"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 4, sm: 4, md: 4 }}
            mb={{ xs: 6, sm: 9, md: 6 }}
            mx={3}
            sx={{ backgroundColor: `rgb(241,241,241)` }}
          >
            <MKBox
              variant="gradient"
              bgColor="dark"
              coloredShadow="info"
              borderRadius="xs"
              p={2}
              mx={0}
              mt={0}
              textAlign="center"
            >
              <MKTypography variant="h3" color="text">
              Esqueceu sua senha?
              </MKTypography>
            </MKBox>

            <MKBox p={3}>
              <MKTypography variant="caption" color="text" mb={3} align="center">
                Sua senha deve ter no mínimo 8 caracter, ser composta por números, letras e ter pelo 
                menos uma Letra maiúscula e um carcter escpecial (!@#$%¨&*)
              </MKTypography>
              <form onSubmit={handleSubmit}>
              <MKBox width="100%" mt={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Grid item xs={12} md={8}>
                      <MKInput
                        type="password"
                        variant="standard"
                        label="Senha"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={password} 
                        onChange={(e) => handlePasswordChange(e.target.value)} 
                        min={8}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <MKInput
                        id="confirmaPassword"
                        type="password"
                        variant="standard"
                        label="Confirme a senha"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={passwordConfirma} 
                        onChange={(e) => setPasswordConfirma(e.target.value)}
                        onBlur={comparaSenhas}
                        onFocus={focusSenhasHandler}
                        min={8}
                        required
                      />
                      { messageSenha ?
                            <div className=" c0l-6 text-center ">
                            <MKTypography variant="button" color="danger" mb={3}> {messageSenha ? messageSenha : null} </MKTypography>
                            </div>
                            : ""
                        }
                    </Grid>

                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <Stack spacing={1} width="100%">
                      <MKProgress color={cor} value={forcaPwd} />
                    </Stack>
                          { message ?
                            <MKTypography variant="button" color={cor} mb={3}> {message ? message : null} </MKTypography>
                            : ""
                          }
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} mt={2} mb={2}>
                    <MKButton type="submit" variant="gradient" color="primary">
                      Alterar
                    </MKButton>&nbsp;
                    <MKButton type="button" variant="gradient" color="success" onClick={ limparDados }>
                      Limpar
                    </MKButton>
                  </Grid>

                  </Grid>
                </Grid>
                </MKBox>
              </form>
                  
              </MKBox>
            </MKBox>

        </Grid>

        </Grid>
                
        </BaseLayout>
           
        </>
    )

}

export default RecuperaSenha;