import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "auxiliares/Navbars/DefaultNavbar";
import DefaultFooter from "auxiliares/Footers/DefaultFooter";
//import Breadcrumbs from "examples/Breadcrumbs";
import routes from "routes";
import footerRoutes from "footer.routes";
import logo from "assets/images/logos/logoZerados.png";

function BaseLayout({ title, children }) {
  let token = getToken();
  function getToken() {
    return localStorage.getItem("token");
  }
  return (
    <MKBox display="flex" flexDirection="column" bgColor="#e2e2e2" minHeight="100vh">
        {/* <div ml={5}><img src={logo} className="img-fluid"/> 
        <MKTypography variant="button" color="text" mb={3}>A plataforma do carro que mais vende em Minas Gerais</MKTypography></div> */}
      <MKBox 
        bgColor="primary" 
        shadow="md" 
        variant="gradient"
        coloredShadow="primary"
        py={0.25}>
        {!token ? (
        <DefaultNavbar
          img={logo}
          routes={routes}
          action={{
            type: "internal",
            route: "/pages/authentication/sign-in",
            label: "Entrar",
            color: "info",
          }}
          transparent
          relative
          light
        />
        ) : (
        <DefaultNavbar
          img={logo}
          routes={routes}
          action={{
            type: "internal",
            route: "/pages/authentication/log-out",
            label: "Logout",
            color: "warning",
          }}
          transparent
          relative
          light
        />
        )
      }
      </MKBox>
      <Container sx={{ mt: 3 }} maxWidth={false}>
        <Grid item xs={12} flexDirection="column" justifyContent="center" mx="auto">
          {/* <MKTypography variant="h3" mb={0}>
            {title}
          </MKTypography>  */}
          {children}
        </Grid>
      </Container>
      <MKBox mt="auto">
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the BaseLayout
BaseLayout.propTypes = {
  breadcrumb: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
