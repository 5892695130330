import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import AnunciosInformation from "./AnunciosInformation";

function AnunciosBlocks() {
  return (
    <MKBox component="section" my={0} py={1}>
      <Container sx={{ mt: 0 }}>
        <AnunciosInformation />
      </Container>
    </MKBox>
  );
}

export default AnunciosBlocks;
