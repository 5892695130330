import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";

import Dashboard from "layouts/pages/dashboard";
import NovoAnuncioPage from "layouts/pages/cadastro/anuncio";
import ListaAnunciosPage from "layouts/pages/cadastro/listaAnuncios";
import EditaAnuncioPage from "layouts/pages/cadastro/editaAnuncio"
import DetalheAnuncioPage from "layouts/pages/anuncio/detalheAnuncio";
import MinhaSenhaRequisicaoPage from "layouts/pages/authentication/senha";
//import MinhaSenhaPage from "layouts/pages/authentication/senha/minhaSenha";
import RecuperaSenhaPage from "layouts/pages/authentication/recuperaSenha/";
import User from "layouts/pages/cadastro/user";
//import Presentation from "layouts/pages/presentation";

import routes from "routes";
import painelRoutes from "painel.routes";

export default function App() {
  const { pathname } = useLocation();
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        { getRoutes(routes) }
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/cadastro/novoanuncio" element={<NovoAnuncioPage />} />
        <Route path="/cadastro/listaanuncios" element={<ListaAnunciosPage />} />
        <Route path="/cadastro/editaanuncio" element={<EditaAnuncioPage />} />
        <Route path="/anuncio/detalhe" element={<DetalheAnuncioPage />} />
        {/* <Route path="/auth/minhasenha" element={<MinhaSenhaPage />} /> */}
        <Route path="/auth/senha" element={<MinhaSenhaRequisicaoPage />} />
        <Route path="/auth/recuperasenha" element={<RecuperaSenhaPage />} />
        <Route path="/cadastro/user" element={<User />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
