//import { useEffect } from "react";
import BaseLayout from "layouts/sections/components/BaseLayout";
import Container from "@mui/material/Container";
import AnuncioInformation from "./sections/AnuncioInformation";

function DetalheAnuncio() {
  return (
    <BaseLayout
      title="Anúncio - Detalhe"
      breadcrumb={[{ label: "home", route: "/" }, { label: "Anúncios" }]}
    >
      <Container sx={{ mt: 0 }}>
        <AnuncioInformation />
      </Container>
    </BaseLayout>
  );
}

export default DetalheAnuncio;
