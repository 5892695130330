import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import imgCarro from "assets/images/car/car.png";

function AnuncioCard({ color, image, marca, modelo, modeloMarca, valor, vistoriado, combustivel, cor, idrf, path}) {
  
  return (
    <MKBox
      variant={color === "transparent" ? "contained" : "gradient"}
      bgColor={color}
      borderRadius="md"
      shadow={color === "transparent" ? "none" : "md"}
      p={3}
    >
      <div className="text-center">
        <img
          src={path != "P" ? path : imgCarro}
          width="250"
          height="150"
          className="rounded fluid" 
        />
        {image}
      </div>
      <MKBox lineHeight={1} mt={2}>
        <MKTypography
          display="block"
          variant={image ? "button" : "h6"}
          fontWeight="bold"
          //color={color === "transparent" || color === "light" ? "dark" : "white"}
          color={"primary"}
          mb={0.5}
        >
          {marca} - {modelo} - {modeloMarca}
        </MKTypography>
        <hr />
        <MKTypography
          display="block"
          fontWeight="regular"
          lineHeight={1}
          color={"text"}
          sx={{ display: "flex", alignItems: "center" }}
        >
          {cor} - {combustivel}
        </MKTypography>
        <MKTypography
          variant={image ? "caption" : "button"}
          fontWeight="regular"
          lineHeight={2}
          color={"success"}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Icon>money</Icon>&nbsp;
          {valor}
        </MKTypography>
      </MKBox>
      <MKTypography
        variant={image ? "caption" : "button"}
        fontWeight="regular"
        color={"text"}
        my={1}
      >
        <i>Vistoriado:</i> {vistoriado} <i>Referência:</i> {idrf}
      </MKTypography>
      <MKTypography
        variant={image ? "caption" : "button"}
        fontWeight="regular"
        lineHeight={1}
        color={"text"}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <a href={ "/anuncio/detalhe?id=" + idrf}> saiba mais...</a>
      </MKTypography>
    </MKBox>
  );
}

AnuncioCard.defaultProps = {
  color: "transparent",
  image: "",
  link:"",
};

AnuncioCard.propTypes = {
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "danger",
  ]),
  image: PropTypes.string,
  marca: PropTypes.string.isRequired,
  modelo: PropTypes.string.isRequired,
  modeloMarca: PropTypes.string.isRequired,
  valor: PropTypes.string.isRequired,
  vistoriado: PropTypes.string.isRequired,
  combustivel: PropTypes.string.isRequired,
  cor: PropTypes.string.isRequired,
  idrf: PropTypes.string.isRequired,
};

export default AnuncioCard;
