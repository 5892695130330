import http from "../utils/http";

const context = "anonimo";

export const getColaboradores = (token) => {
  return http.post(`/token`, token).then(result => result.data)
}

export const gravaNovoColaborador = async (usr) => {
  const result = await http.post(`/${context}/gravaUsuario`, usr);
  return result.data;
}

export const getUfs = () => {
  return http.get(`/${context}/getUfs`).then(result => result.data)
}

export const getCidadesPorUf = (uf) => {
  return http.get(`/${context}/getCidadesPorUf?uf=${uf}`).then(result => result.data)
}
