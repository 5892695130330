import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Divider from "@mui/material/Divider";

import MKBox from "components/MKBox";

import PerfilCard from "auxiliares/Cards/ServiceCards/PerfilCard";

function AuthService() {
  const navigate = useNavigate();
  const [ user, setUser ] = useState("");

  useEffect(() => {
    let tk = getToken();
    getUsuarioLogado();
    console.log(user);
    if (!tk || tk === "0") {
      console.log("token é nulo");
      navigate("/pages/authentication/sign-in");
    }
  }, []);

  function getToken() {
    return localStorage.getItem("token");
  }

  function getUsuarioLogado() {
    let usuario =  JSON.parse(localStorage.getItem("usuarioLogado"));
    setUser(usuario);
  }

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} md={12} lg={12}>
            <PerfilCard
              titulo="Meus Dados"
              nome={user.nome}
              link="/cadastro/user"
              icone="face"
              email={user.login}
              perfil={user.perfil}
              plano={user.plano}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AuthService;
