import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

import DetalheAnuncioCard from "auxiliares/Cards/ReviewCards/AnuncioCard/detalheAnuncioCard";

import useQuery from "../components/useQuery";
import { getAnuncioPorId } from "../../../services/BuscaService";

function AnuncioInformation() {
  const query = useQuery();
  const [anuncio, setAnuncio] = useState();
  const [listaDeOpcionais, setListaDeOpcionais ] = useState([]);
  const [ listaDeImagens, setListaDeImagens ] = useState([]);
  
  const getAnuncio = (idAnuncio) => {
    setAnuncio();
    getAnuncioPorId(idAnuncio)
      .then((result) => {
        if (result) {
          setAnuncio(result);
          setListaDeOpcionais(result.opcionais);
          setListaDeImagens(result.imagens);
        } else {
          alert("Nenhum anúncio encontrado");
        }
      })
      .catch(console.log(""));
  };

  useEffect(() => {    
    let idAnuncio = 0;
    idAnuncio = query.get("id");
    if (!idAnuncio || idAnuncio === " ") {
      return;
    } 
    getAnuncio(idAnuncio);    
  }, []);

  return (
    <>
    <MKBox component="section" py={0} mb={5}>
        <Grid container spacing={1} sx={{ mt: 0 }}>
          {anuncio ?
              <Grid item xs={12} md={12} lg={12}>
                <DetalheAnuncioCard
                  image={anuncio.path}
                  marca={anuncio.nomeMarca}
                  modelo={anuncio.nomeModelo}
                  modeloMarca={anuncio.nomeModeloMarca}
                  valor={" " + anuncio?.valor?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                  vistoriado={anuncio.vistoriado}
                  path={anuncio.imagemPadrao}
                  cor={anuncio.cor}
                  combustivel={anuncio.combustivel}
                  idrf={""+anuncio.idAnuncio}
                  color="light"
                  opcionais={listaDeOpcionais}
                  imgs={listaDeImagens}
                  sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: 0,
                    mb: 4,
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                    //backgroundColor: "rgba(255, 255, 255, .8)",
                    backdropFilter: "saturate(200%) blur(30px)",
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  }}
                  dados={anuncio}
                />


              </Grid>
              : ""}
        </Grid>
    </MKBox>
    </>
  );
}

export default AnuncioInformation;
