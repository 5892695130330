import * as React from 'react';
import { useState, useEffect } from "react";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { sendMessage } from "services/AnonimoService"
import Contact from "pages/LandingPages/ContactUs/sections/Contact";

function ContactUs() {
  
  useEffect(() => { 
  }, []);

  return (
    <>
    <BaseLayout
      title="Fale Conosco"
      breadcrumb={[{ label: "home", route: "/" }, { label: "Fale Consosco" }]}
    >
      <Contact />
    </BaseLayout>
    </>
  );
}

export default ContactUs;
