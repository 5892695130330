import React from "react";
//import { Navigate } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
//import Icon from "@mui/material/Icon";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import BaseLayout from 'layouts/sections/components/BaseLayout';
import DefaultFooter from "auxiliares/Footers/DefaultFooter";

import AuthService from "pages/Painel/sections/AuthService";
import Service from "pages/Painel/sections/Service";

import bgImage from "assets/images/city-profile.jpg";

function Painel() {
  return (
    <>
    <BaseLayout
      title="Painel de controle"
      breadcrumb={[{ label: "home", route: "/" }, { label: "Painel de controle" }]}
    >
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 0,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          //backgroundColor: "rgba(255, 255, 255, .8)",
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <AuthService />
        <Service />
      </Card>
      </BaseLayout>
    </>
  );
}

export default Painel;
