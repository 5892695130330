import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
//import MuiLink from "@mui/material/Link";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import BaseLayout from "layouts/sections/components/BaseLayout";
import SimpleFooter from "auxiliares/Footers/SimpleFooter";

import imgCarro1 from "assets/images/car/carro1.jpg";
import routes from "routes";

function SignInBasic() {
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [username, setUserName] = useState("");
  const [pwd, setPwd] = useState("");
  //const [clientId, setClienteId] = useState("");
  //const [tokenUsuario, setTokenUsuario] = useState("");
  const [message, setMessage] = useState("");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  useEffect(() => {
    const token = getToken();
    if (token && token !== "0") {
      navigate("/pages/painel");
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    if(username === "" || username === null || pwd === "" || pwd === null){
      setMessage("Oi, login e/ou senha não foram informados!");
      return;
    }
    const dados =
      "userName=" + username + "&password=" + pwd + "&grant_type=password&clientId=&tokenUsuario=";

    try {
      //const url = process.env.APP_API_ENDPOINT;
      let response = await fetch("https://zeradoswprod.azurewebsites.net/token", {
      //let response = await fetch("https://localhost:44306/token", {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
        body: dados,
      });

      let resJson = await response.json();

      if (response.status === 200) {
        setUserName("");
        setPwd("");
        localStorage.setItem("token", resJson.access_token);
        localStorage.setItem("usuarioLogado", JSON.stringify(resJson));
        navigate("/pages/painel");
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("usuarioLogado");
        setMessage("Credenciais inválidas!!!");
      }
    } catch (err) {
      setMessage(":-( !!! Não foi possível processar sua requição!.");
      alert(err);
      console.log(err);
    }
  }

  // function limparDados() {
  //   setUserName("");
  //   setPassword("");
  //   setMessage("");
  // }

  function getToken() {
    return localStorage.getItem("token");
  }

  return (
    <>
    <BaseLayout
      title=""
      breadcrumb={[{ label: "home", route: "/" }, { label: "Sign In" }]}
    >

      <MKBox px={0} width="100%" height="auto" mx="auto" position="relative" zIndex={2} mb={5}>
        <Grid container spacing={0} justifyContent="center" alignItems="center"  mt={2}>

          <Grid item xs={12} sm={9} md={8} lg={8} xl={6}>
              <MKBox pt={4} pb={3} px={3} borderRadius="sm" sx={{ backgroundColor: `rgb(241,241,241)` }}>

              <Grid container spacing={2}>
                <Grid container sapcing={2} justifyContent="center" alignItems="center">
                  <MKTypography variant="h5" mb={2} sx={{textAlign: `center`}}>A plataforma do carro que mais cresce em Minas</MKTypography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} xl={6}>
                  <img src={imgCarro1} className="rounded img-fluid" ></img>
                </Grid>
                <Grid item xs={12} xl={6}>
                  <MKBox component="form" role="form">
                    <MKBox mb={2}>
                      <MKInput
                        type="email"
                        variant="standard"
                        label="login"
                        fullWidth
                        value={username}
                        onChange={(e) => setUserName(e.target.value)}
                        onBlur={(e) => setMessage("")}
                      />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput
                        type="password"
                        variant="standard"
                        label="senha"
                        fullWidth
                        value={pwd}
                        onChange={(e) => setPwd(e.target.value)}
                        onBlur={(e) => setMessage("")}
                      />
                    </MKBox>

                    <MKBox mt={4} mb={1} textAlign="center">
                      <MKButton
                        type="submit"
                        variant="gradient"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Entrar 
                      </MKButton>
                      <br />
                      <MKTypography
                        variant="text"
                        color="danger"
                        fontWeight="light"
                        textGradient
                      >
                        {message}
                      </MKTypography>
                      
                    </MKBox>
                    <MKBox mt={3} mb={1} textAlign="center" >
                      <MKTypography variant="button" color="text">
                        Ainda não tem uma conta?{" "}
                        <MKTypography
                          component={Link}
                          to="../pages/authentication/register"
                          variant="button"
                          color="info"
                          fontWeight="medium"
                          textGradient
                        >
                          Faça seu cadastro
                        </MKTypography>
                      </MKTypography>
                    </MKBox>

                    <MKBox mt={1} mb={1} textAlign="center" >
                      <MKTypography variant="button" color="text">
                        
                        <MKTypography
                          component={Link}
                          to="/auth/senha"
                          variant="button"
                          color="info"
                          fontWeight="medium"
                          textGradient
                        >
                          Esqueceu a senha?
                        </MKTypography>
                      </MKTypography>
                    </MKBox>

                  </MKBox>
                </Grid>
                </Grid>
              </MKBox>
              

          </Grid>
        </Grid>
      </MKBox>
      {/* <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox> */}
      </BaseLayout>
    </>
  );
}

export default SignInBasic;
