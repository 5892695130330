import * as React from 'react';
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Divider from "@mui/material/Divider";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from "@mui/material/Stack";
import MKProgress from "components/MKProgress";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Modal from "@mui/material/Modal";
import BaseLayout from 'layouts/sections/components/BaseLayout';
import { AccessAlarm, Newspaper, ViewHeadline, Ballot } from '@mui/icons-material';
import { ForcaSenha } from "../../../services/UtilService";
import { getCidadesPorUf, getUfs } from "../../../services/ColaboradorService";
import { getUserById, saveUser } from "../../../services/UsuarioService";
import Slide from "@mui/material/Slide";
import ClipLoader from "react-spinners/PropagateLoader";
import { getCepAddress } from 'services/AnonimoService';

const override = {
  display: "block",
  margin: "50vh",
  borderColor: "#1065c2",
  zIndex: 999,
};

function User() {
  const [loading, setLoading] = useState(true);
    const [ message, setMessage ] = useState("");
    const [ messageEmail, setMessageEmail ] = useState("");
    const [ msgErroCampo, setMsgErroCampo ] = useState("");
    const [ messageSenha, setMessageSenha ] = useState("");
    const [ values, setValues ] = useState({
      idUsuario: "",
      userName: "",
      perfil: "",
      dataCadastro: "",
      dataValidade: "",
      idPessoa: "",
      dataAtualizacao: "",
      primeiroAcesso: "",
      password: "",
      idPessoa: "",
      nome: "",
      email: "",
      cpfCnpj: "",
      telefone: "",
      celular: "",
      ruaAv: "",
      numero: "",
      bairro: "",
      complemento: "",
      idCidade: "",
      nomeCidade: "",
      codUf: "",
      cep: "",
    });
    const [password, setPassword] = useState("");
    const [passwordConfirma, setPasswordConfirma] = useState("");
    const [ usrLogado, setUsrLogado ] = useState({});
    const [ listaDeCidades, setListaDeCidades ] = useState([]);
    const [ listaDeUfs, setListaDeUfs ] = useState([]); 
    const [ plano, setPlano ] = useState("");
    const [ ufSelecionada, setUfSelecionada ] = useState("");
    const [ cidadeSelecionada, setCidadeSelecionada ] = useState("");
    const [ pwdValida, setPwdValida ] = useState(false);
    const [ cor, setCor ] = useState("light");
    const [ forcaPwd, setForcaPwd ] = useState(0);
    const [ show, setShow ] = useState(false);
    const toggleModal = () => setShow(!show);   

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setValues((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    };

    const handleSubmit = (event) => {
      setMessage(""); 
      event.preventDefault();
      values.password = password;
      const cel = values.celular;
      values.celular = cel != "" ? cel.replace("(","").replace(")","").replace("-","").replace(" ","") : "";
      const tel = values.telefone;
      values.telefone = tel != "" ? tel.replace("(","").replace(")","").replace("-","").replace(" ","") : "";
      const cep = values.cep;
      values.cep = cep != "" ? cep.replace("(","").replace(")","").replace("-","").replace(" ","").replace(".","") : "";

      saveUser(values)
      .then(result => { 
        if (result) {
          //setAnuncios(result);
          const id = values.idPessoa;
          getUserData(id);
          setMessage("Endereço encontrado!");
        } else {
          setMessage("Nenhum endereço encontrado...!");
        }
      })

    };

    let isNumber = (n) => {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const getUserData = async (id) =>{
      getUserById(parseInt(id))
      .then(result => {
        if(result){
          console.log("=> ",result);
          result.pessoa.cpfCnpj = formataCpf(result.pessoa.cpfCnpj);
          result.pessoa.telefone = formataTelefone(result.pessoa.telefone);
          result.pessoa.celular = formataCelular(result.pessoa.celular);
          montaListaDeCidades(result.pessoa.cidade.codUf);
          setValues({...values, nome: result.pessoa.nome, 
                      userName: result.login, 
                      cpfCnpj: result.pessoa.cpfCnpj,
                      celular: result.pessoa.celular,
                      telefone: result.pessoa.telefone,
                      idUsuario: result.idUsusario,
                      perfil: result.perfil,
                      idPessoa: result.pessoa.idPessoa,
                      primeiroAcesso: "N",
                      password: "",
                      ruaAv: result.pessoa.ruaAv,
                      numero: result.pessoa.numero,
                      bairro: result.pessoa.bairro,
                      complemento: result.pessoa.complemento,
                      idCidade: result.pessoa.idCidade,
                      nomeCidade: "",
                      codUf: result.pessoa.cidade.codUf,
                      cep: result.pessoa.cep,
                    });
        }
      });   
    }

    useEffect(() => {  
        setLoading(true);
        montaListaUfs();
        const u = JSON.parse(localStorage.getItem("usuarioLogado"))
        setUsrLogado(u);
        console.log("IdUser => ", u.idUsuario);
        getUserData(u.idUsuario);   
        setLoading(false);        
    }, []);
  
    // const handleChange = prop => event => {
    //   setValues({ ...values, [prop]: event.target.value });
    // };  

    let montaListaUfs = () => {
        getUfs()
          .then(result => {
            if (result) {
              const ufs = result;
              setListaDeUfs(ufs);            
            }
          })
      }

      let montaListaDeCidades = (uf) => {
        getCidadesPorUf(uf)
          .then(result => {
            if (result) {
              const cidades = result;
              setListaDeCidades(cidades); 
              console.log(cidades.first);      
              setCidadeSelecionada(cidades.first);     
            }
          })
      }      

      let formataCelular = (e) => {
        if(e === null || e === "") return;
        e = e.replace("(","").replace(")","").replace("-","").replace(" ","");
        if(!isNumber(e)){
          setMsgErroCampo("CELULAR")
          toggleModal();
          return;
        }        
        if(e.length < 11)
        {
          setMsgErroCampo("CELULAR")
          toggleModal();
          return;
        }
        else{
          var x = e.replace(/\D/g, '').match(/(\d{2})(\d{5})(\d{4})/);
          e = '(' + x[1] + ') ' + x[2] + '-' + x[3];
        }
        return e;
      }
    
      let formataTelefone = (t) => {
        if(t === null || t === "") return;
        t = t.replace("(","").replace(")","").replace("-","").replace(" ","");
        if(!isNumber(t)){
          setMsgErroCampo("TELEFONE")
          toggleModal();
          return;
        }        
        if(t.length < 10)
        {
          setMsgErroCampo("TELEFONE")
          toggleModal();
          return;
        }
        else{
          var x = t.replace(/\D/g, '').match(/(\d{2})(\d{4})(\d{4})/);
          t = '(' + x[1] + ') ' + x[2] + '-' + x[3];
        }
        return t;
      }    
      
      let formataCpf = (cpf) => {
        if(cpf === null || cpf === "") return;
        cpf = cpf.replace(".","").replace(".","").replace("-","").replace(" ","");
        if(!isNumber(cpf)){
          setMsgErroCampo("CPF")
          toggleModal();
          return;
        }
        if(cpf.length < 11)
        {
          setMsgErroCampo("CPF")
          toggleModal();
          return;
        }
        else{
          var x = cpf.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{3})(\d{2})/);
          cpf = x[1] + '.' + x[2] + '.' + x[3]+ '-' + x[4];
        }
        return cpf;
      }

      const focusSenhasHandler = () => {
        setMessageSenha("");
      }
    
      const handlePasswordChange = (e) =>{
        setPassword(e);
        validarForcaSenha(e);
        const { name, value } = {"password": e};
        setValues((prevUser) => ({
          ...prevUser,
          [name]: value,
        }));
      }
    
      const focusHandler = () => {
        setMessageEmail("");
      }
    
      const blurHandler = () => {
        validaUsername(username);        
      }

      let comparaSenhas =() => {
        setMessageSenha("");
        if(password !== passwordConfirma )
        {
          setMessageSenha("As senhas não conferem !!!");
          setPasswordConfirma("");
        }
        else
        {
          setMessageSenha("");
        }
      };

      let validaUsername = (username) => {
        if (username !== "undefined") {
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(username)) {        
            setMessageEmail("ATENÇÃO!! Este não parece se um e-mail válido.");
            setUserName("");
            return false;
          }
          else
          {
            setUserName(username);
            return true;
          }
        }
      }

      const validarForcaSenha = (pwd) => {

        if (pwd != null) {
          const fSenha = ForcaSenha(pwd);
          const bufferValue = fSenha;
          const textoForcaSenha = '';
    
          if (fSenha <= 40) {
            setMessage('A senha não atende os requisitos necessários!');
            setCor("error");
            setPwdValida(false);          
            setForcaPwd(fSenha);
          }
    
          if (fSenha > 40 && fSenha <= 70) {
            
            setMessage('A senha ainda NÃO atende aos requisitos necessários!');
            setPwdValida(false);
            setCor("warning");
            setForcaPwd(fSenha);
          }
    
          if (fSenha > 70 && fSenha <= 90) {
            setMessage('Sua senha é fraca, mas ATENDE aos requisitos necessários!');
            setPwdValida(true);
            setCor("primary");
            setForcaPwd(fSenha);
          }
    
          if (fSenha > 90) {
            setMessage('Senha forte! ATENDE aos requisitos necessários!');
            setPwdValida(true);
            setCor("success");
            setForcaPwd(100);
          }
        } else {
          setMessage("");
          setCor("");
        }  
      }   
      
      let handleSelectUfChange = (e) => {        
        values.codUf = e;
        getCidadesPorUf(e)
          .then(result => {
          if (result) {
            const cidades = result;
            setListaDeCidades(cidades);     
            values.idCidade = cidades[0].idCidade;       
          }
        });
      }

      let handleInputCepChange = (e) => {     
        if(e.length != 8){ 
          alert("Cep inválido!!");
          values.cep = "";
          return;
        }   
        e = e.replace("(","").replace(")","").replace("-","").replace(" ","").replace(".","");
        getCepAddress(e)
          .then(result => {
          if (result) {
            console.log(result);            
            setValues((prevUser) => ({
              ...prevUser,
              ["ruaAv"]: result.logradouro,
              ["codUf"]: result.uf,
              ["bairro"]: result.bairro,
            }));      
          }
        });
      }

    return (
    <>
        <BaseLayout
        title="Meus dados"
        breadcrumb={[{ label: "home", route: "/" }, { label: "Meus DAdos" }]}
        >

      <ClipLoader color="#0000ff" cssOverride={override} loading={loading} size={35} />

        <MKBox px={0} width="100%" height="auto" mx="auto" position="relative" zIndex={2} mb={5}>
        <Grid container spacing={0} justifyContent="center" alignItems="center" mt={2}>

          <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
              <MKBox pt={4} pb={3} px={3} borderRadius="sm" sx={{ backgroundColor: `rgb(241,241,241)` }}>

              <Grid container spacing={2}>
                <Grid container sapcing={2} justifyContent="center" alignItems="center">
                  <MKTypography variant="h5" mb={0} sx={{textAlign: `center`}}>Seus dados no zerados.com.br</MKTypography>
                </Grid>
                <Grid container sapcing={2} justifyContent="center" alignItems="center">
                  <MKTypography variant="h6" mb={2} sx={{textAlign: `center`}}>A plataforma do carro que mais cresce em Minas</MKTypography>
                </Grid>
              </Grid>

              <Grid container spacing={0}>


              <Grid item xs={12} xl={12}>
                <MKBox
                  bgColor="white"
                  borderRadius="md"
                  sx={{ backgroundColor: `rgb(241,241,241)` }}
                >
                  <MKBox p={3}>
                    <form onSubmit={handleSubmit}>
                    <MKBox width="100%">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="plano">Planos</InputLabel>
                            <Select labelId="plano" id="planos" value={1} onChange={(e) => setPlano(e.target.value)} 
                              label="Plano" required readOnly>
                              <MenuItem value="0"><em>Lançamento</em></MenuItem>
                              <MenuItem value="1">Plano 1.0</MenuItem>
                              <MenuItem value="2">Plano 1.2</MenuItem>
                              <MenuItem value="3">Plano 1.4</MenuItem>
                              <MenuItem value="4">Plano 2.0 Turbo</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            placeholderid="___.___.___-__"
                            variant="standard"
                            label="CPF / CNPJ"
                            name="cpfCnpj"
                            InputLabelProps={{ shrink: true }}
                            fullWidth 
                            value={values.cpfCnpj} 
                            readOnly
                            required
                            //onChange={handleInputChange}
                            //onBlur={(e) => formataCpf(e.target.value)}                            
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            variant="standard"
                            label="Nome completo"
                            name="nome"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={values.nome} 
                            onChange={handleInputChange}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            type="email"
                            variant="standard"
                            label="Login"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={values.userName} 
                            onChange={handleInputChange}
                            readOnly
                            required
                          />
                          { messageEmail ?
                                <div className=" c0l-6 text-center ">
                                <MKTypography variant="button" color="danger" mb={3}> {messageEmail ? messageEmail : null} </MKTypography>
                                </div>
                                : ""
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            type="password"
                            variant="standard"
                            label="Senha"
                            name="password"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value = {password}
                            onChange={(e) => handlePasswordChange(e.target.value)} 
                            min={8}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            id="confirmaPassword"
                            type="password"
                            variant="standard"
                            label="Confirme a senha"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value = {passwordConfirma} 
                            onChange={(e) => setPasswordConfirma(e.target.value)}
                            onBlur={comparaSenhas}
                            onFocus={focusSenhasHandler}
                            min={8}
                            required
                          />
                          { messageSenha ?
                                <div className=" c0l-6 text-center ">
                                <MKTypography variant="button" color="danger" mb={3}> {messageSenha ? messageSenha : null} </MKTypography>
                                </div>
                                : ""
                            }
                        </Grid>
                        <Grid container item justifyContent="center" xs={12} mt={1} mb={1}>
                          <Stack spacing={1} width="100%">
                            <MKProgress color={cor} value={forcaPwd} />
                          </Stack>
                                { message ?
                                  <MKTypography variant="button" color={cor} mb={1}> {message ? message : null} </MKTypography>
                                  : ""
                                }
                          <MKTypography variant="button" color="text" mb={1}>
                            Sua senha deve ter no mínimo 8 caracter, ser composta por números, letras e ter pelo 
                            menos uma Letra maiúscula e um carcter escpecial (!@#$%¨&*)
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            placeholder="(__) _____-____"
                            value={values.celular}
                            type="tel"
                            variant="standard"
                            label="Celular"
                            name="celular"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={handleInputChange}
                            onBlur={(e) => formataCelular(e.target.value)}
                            //onFocus={(e) => formataCelular(e.target.value)}
                            required
                          />
                        </Grid>                  
                        <Grid item xs={12} md={6}>
                          <MKInput
                            placeholder="(__) ____-____"
                            value={values.telefone}
                            type="tel"
                            variant="standard"
                            label="Telefone"
                            name="telefone"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={handleInputChange}
                            onBlur={(e) => formataTelefone(e.target.value)}
                          />
                        </Grid>

                        <Grid item xs={12} md={2}>
                          <MKInput
                            placeholder="cep"
                            value={values.cep}
                            type="text"
                            variant="standard"
                            label="Cep"
                            name="cep"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={handleInputChange}
                            onBlur={(e) => handleInputCepChange(e.target.value)}
                            required
                          />
                        </Grid>                  
                        <Grid item xs={12} md={6}>
                          <MKInput
                            placeholder="rua / av"
                            value={values.ruaAv}
                            type="text"
                            variant="standard"
                            label="Endereço"
                            name="rua"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <MKInput
                            placeholder="número"
                            value={values.numero}
                            type="text"
                            variant="standard"
                            label="Número"
                            name="numero"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <MKInput
                            placeholder="complemento"
                            value={values.complemento}
                            type="text"
                            variant="standard"
                            label="Complemento"
                            name="complemento"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={handleInputChange}
                            required
                          />
                        </Grid>  

                        <Grid item xs={12} md={3}>
                          <MKInput
                            placeholder="bairro"
                            value={values.bairro}
                            type="text"
                            variant="standard"
                            label="Bairro"
                            name="bairro"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="uf">Uf</InputLabel>
                            <Select labelId="uf" id="ufs" value={values.codUf} onChange={(e) => handleSelectUfChange(e.target.value)} 
                              label="UF" required>
                              <MenuItem value=""><em>selecione a UF</em></MenuItem>
                              { listaDeUfs.map((uf) => (<MenuItem key={uf.codUf} value={uf.codUf}>{uf.descricao}</MenuItem>))} 
                            </Select>
                          </FormControl>

                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="idCidade">Cidade</InputLabel>
                            <Select labelId="idCidade" id="idCidade" name="idCidade" value={values.idCidade} onChange={handleInputChange} 
                              label="Cidade" required>
                              <MenuItem value=""><em>selecione a Cidade</em></MenuItem>
                              { listaDeCidades.map((cidade) => (<MenuItem key={cidade.idCidade} value={cidade.idCidade}>{cidade.nome}</MenuItem>))} 
                            </Select>
                          </FormControl>                    
                        </Grid>

                      </Grid>
                      <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>                        
                        <MKButton type="submit" variant="gradient" color="primary">
                          Gravar
                        </MKButton>&nbsp;
                      </Grid>
                    </MKBox>
                    </form>
                  </MKBox>
                </MKBox>
              </Grid>
      </Grid>
      </MKBox>
      </Grid>
      </Grid>
      </MKBox>
      
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={show} timeout={500}>
          <MKBox
            position="relative"
            width="450px"
            display="flex"
            flexDirection="column"
            borderRadius="md"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">ATENÇÃO</MKTypography>
              {/* <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} /> */}
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
                <MKTypography variant="body2" color="text" fontWeight="regular">
                    O valor informado não parece ser um valor válido para o campo <span><i>{msgErroCampo}</i></span> .
                </MKTypography> 
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="danger" onClick={toggleModal}>
                fechar
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>



        </BaseLayout>
    </>
    );
}
export default User;
