import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";
import BaseLayout from "layouts/sections/components/BaseLayout";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/city-profile.jpg";
import { useNavigate } from "react-router-dom";

function AboutUs() {
  const navigate = useNavigate();
  let registrar = () => {
    navigate("../pages/authentication/register");
  };
  //const minhaUrl = "../pages/authentication/register";
  return (
    <>
    <BaseLayout
      title="A Empresa"
      breadcrumb={[{ label: "home", route: "/" }, { label: "E Empresa" }]}
    >
      <MKBox
        minHeight="45vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        {/* <Container> */}
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Nós valorizamos sua privacidade
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Não compartilhamos dados de nossos clientes que outras empresas.
            </MKTypography>
            <MKButton
              color="default"
              sx={{ color: ({ palette: { dark } }) => dark.main }}
              onClick={registrar}
            >
              Crie sua conta
            </MKButton>
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              Siga-nos
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-facebook" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-twitter" />
              </MKTypography>
            </MKBox>
          </Grid>
        {/* </Container> */}
      </MKBox>
      <Grid
        sx={{
          borderRadius: 2,
          p: 1,
          mx: { xs: 1, lg: 0 },
          mt: 4,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          //backgroundColor: "rgba(16, 101, 194, .2)",
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
      </Grid>
      </BaseLayout>
    </>
  );
}

export default AboutUs;
