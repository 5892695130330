//import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import MKBox from "components/MKBox";

//import DefaultReviewCard from "auxiliares/Cards/ReviewCards/DefaultReviewCard";
import ServiceCard from "auxiliares/Cards/ServiceCards";

function Service() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6} lg={4}>
            <ServiceCard
              titulo="Meus anúncios"
              descricao="Lista dos meus anúncios"
              link="/cadastro/listaanuncios"
              icone="newspaper"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ServiceCard
              titulo="Novo anúncio"
              descricao="Cadastro de novo anúncio"
              link="/cadastro/novoanuncio"
              icone="newspaper"
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4}>
            <ServiceCard
              titulo="Meus Dados"
              descricao="Visualização do cadastro"
              link="/pages/cadastro"
              icone="face"
            />
          </Grid> */}
          <Grid item xs={12} md={6} lg={4}>
            <ServiceCard
              titulo="Propostas"
              descricao="Recebidas e ofertadas"
              link="/pages/cadastro"
              icone="money"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ServiceCard
              titulo="Minha assinatura"
              descricao="Dados da Minha assinatura"
              link="pages/cadastro"
              icone="modeedit"
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 1 }} />
      </Container>
    </MKBox>
  );
}

export default Service;
