import { useState } from "react";
//import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { sendMessage } from "services/AnonimoService"
import bgImage from "assets/images/examples/blog2.jpg";

function Contact() {
  const [ message, setMessage ] = useState("");
  const [ formData, setFormData] = useState({
    mailto: "cristianobh2006@hotmail.com",
    mailSubject: "Fale Conosco",
    assunto: "-",
    nome: "",
    email: "",
    phone: "",
    mensagem: "",
    template: "faleconosco",
    idReferencia: "-",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendMessage(formData)
    .then(result => { 
      if (result) {
        setMessage("Mensagem enviada com sucesso!!!");
      }
      else
      {
        setMessage("Não foi possível enviar sua mensagem.");
      }
    })
  };
  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      {/* <Container> */}
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="md"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Informações de Contato
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Preencha o formulário e clique no botão enviar.
                      Alguém da nossa equipe entrará em contato o mais rápido possível.
                    </MKTypography>
                    {/* <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        (+40) 772 100 200
                      </MKTypography>
                    </MKBox> */}
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        contato@zerados.com.br
                      </MKTypography>
                    </MKBox>
                    {/* <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        Dyonisie Wolf Bucharest, RO 010458
                      </MKTypography>
                    </MKBox> */}
                    <MKBox mt={3}>
                      <MKButton variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      {/* <MKButton variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-twitter" style={{ fontSize: "1.25rem" }} />
                      </MKButton> */}
                      {/* <MKButton variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-dribbble" style={{ fontSize: "1.25rem" }} />
                      </MKButton> */}
                      <MKButton variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-instagram" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>

              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Diga olá!
                    </MKTypography>
                    <MKTypography variant="caption" color="text" mb={2}>
                    
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput required={true}
                          variant="standard"
                          label="Meu nome é"
                          placeholder="Nome"
                          name="nome"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput required={true}
                          variant="standard"
                          label="Meu e-mail"
                          placeholder="e-mail"
                          name="email"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput required={true}
                          variant="standard"
                          label="Meu telefone"
                          placeholder="telefone"
                          name="phone"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput required={true}
                          variant="standard"
                          label="Mensagem"
                          placeholder="mensagem..."
                          name="mensagem"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                          onChange={handleInputChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton type="submit" variant="gradient" color="info" onClick={handleSubmit}>
                        Enviar
                      </MKButton>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                      {message ?
                      <MKTypography variant="body2" color="warning" mb={3}>                    
                        {message}
                      </MKTypography>
                      : ""
                      }
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      {/* </Container> */}
    </MKBox>

  );
}

export default Contact;
