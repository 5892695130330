import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Já temos a confiaça de mais de </MKTypography>
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            1524+ anunciantes
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Nossos clientes estão satisfeitos e apoiam o nosso projeto.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Fernando Redoan"
              date="1 dia"
              review="Não existe melhor plataforma para negociar. Anunciei meu carro para venda e realizei o negócio no dia seguinte á publicação do anúncio."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="José Maranhão"
              date="2 dias"
              review="Impressionate a rapidez de negociação nesta plataforma!!!"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Samuel kalil"
              date="2 dias"
              review="Plataforma fácil de usar, serviço de primeira. Recomendo!!!"
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
      </Container>
    </MKBox>
  );
}

export default Information;
