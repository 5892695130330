import BaseLayout from "layouts/sections/components/BaseLayout";
import PriceBlocks from "pages/Price/Sections/PriceBlocks";

function Price() {
  return (
    <BaseLayout
      title="Nossos preços"
      breadcrumb={[{ label: "home", route: "/" }, { label: "Nossos preços" }]}
    >
      <PriceBlocks />
    </BaseLayout>
  );
}

export default Price;
