//import { Usuario } from './../models/Usuario.model';
import http from '../utils/http';

  const context = "usuario";
  
  // export const getUsuarios = () => {
  //   return http.get<Usuario[] | undefined>(`/${context}/getUsuarios`).then(result => result.data)
  // }

  // export const getUsuarioById = (id: number) => {
  //   return http.get<Usuario | undefined>(`/${context}/GetUsuarioPorId/${id}`).then(result => result.data)
  // }

  // export const gravaNovoUsuario = (usr: Usuario) => {
  //   return http.post<boolean | undefined>(`/${context}/gravarUsuario`, usr).then(result => result.data)
  // }

  export const getUserById = (id) => {
    return http.get(`/${context}/getUserById/${id}`).then(result => result.data)
  }

  export const saveUser = (user) => {
    return http.put(`/${context}/editaUsuario/`, user).then(result => result.data)
  }