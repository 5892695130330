import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from "@mui/material/Stack";

import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKProgress from "components/MKProgress";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

import BaseLayout from 'layouts/sections/components/BaseLayout';
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

//import routes from "routes";
import footerRoutes from "footer.routes";

import bgImage from "assets/images/car/carro2.jpg";

import { gravaNovoColaborador, getCidadesPorUf, getUfs } from "../../../services/ColaboradorService";
// import { Celebration } from '@mui/icons-material';
// import $ from 'jquery';
import { ForcaSenha } from "../../../services/UtilService";

function Register() {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirma, setPasswordConfirma] = useState("");
  const [message, setMessage] = useState("");
  const [messageEmail, setMessageEmail] = useState("");
  const [messageSenha, setMessageSenha] = useState("");
  const [ msgErroCampo, setMsgErroCampo ] = useState("");
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [celular, setCelular] = useState("");
  const [telefone, setTelefone] = useState("");
  const [plano, setPlano] = useState("");
  const [ cidadeSelecionada, setCidadeSelecionada ] = useState("");
  const [ ufSelecionada, setUfSelecionada ] = useState("");
  const [ pwdValida, setPwdValida] = useState(false);
  const [ cor, setCor ] = useState("light");
  const [ forcaPwd, setForcaPwd] = useState(0);
  const [ listaDeCidades, setListaDeCidades ] = useState([]);
  const [ listaDeUfs, setListaDeUfs ] = useState([]); 
  const [usuarioModel, setUsuarioModel] = useState({});

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);


  useEffect(() => {
    montaListaUfs();
  }, []);

  let handleSubmit = async (e) => { 
    setMessage("");       
    e.preventDefault();
    try {
      setLoading(true);
      let usr = getColaborador();
      setUsuarioModel(JSON.stringify(usr));
      console.log("Usuário", usr);
      gravaNovoColaborador(usr)
      .then(result => {
        //let resJson = await response.json();

        if (result.status === 200) {
          setLoading(false);
          setMessage("Usuário gravado com sucesso!!! :-) Vá para a página de login.");
          //navigate("/login");
        }
        else
        {
          setMessage("Não foi possível gravar este usuário. Verifique os dados e a sua conexão com a internet.");
          setLoading(false);
        }
      })
    } catch (err) {
      setLoading(false);
      console.log(err);
      //setMessage("Algo ruim aconteceu!!! :-(" + err);
    }
  };

  let formataCelular = (e) => {
    if(e === null || e === "") return;
    if(!isNumber(e)){
      setMsgErroCampo("CELULAR")
      toggleModal();
      return;
    }
    e = e.replace("(","").replace(")","").replace("-","").replace(" ","");
    if(e.length < 11)
    {
      setMsgErroCampo("CELULAR")
      toggleModal();
      return;
    }
    else{
      var x = e.replace(/\D/g, '').match(/(\d{2})(\d{5})(\d{4})/);
      e = '(' + x[1] + ') ' + x[2] + '-' + x[3];
    }
    setCelular(e);
  }

  let formataTelefone = (t) => {
    if(t === null || t === "") return;
    if(!isNumber(t)){
      setMsgErroCampo("TELEFONE")
      toggleModal();
      return;
    }
    t = t.replace("(","").replace(")","").replace("-","").replace(" ","");
    if(t.length < 11)
    {
      setMsgErroCampo("TELEFONE")
      toggleModal();
      return;
    }
    else{
      var x = t.replace(/\D/g, '').match(/(\d{2})(\d{4})(\d{4})/);
      t = '(' + x[1] + ') ' + x[2] + '-' + x[3];
    }
    setTelefone(t);
  }

  let formataCpf = (cpf) => {
    if(cpf === null || cpf === "") return;
    cpf = cpf.replace(".","").replace(".","").replace("-","").replace(" ","");
    if(!isNumber(cpf)){
      setMsgErroCampo("CPF")
      toggleModal();
      return;
    }
    //cpf = cpf.replace("(","").replace(")","").replace("-","").replace(" ","");
    if(cpf.length < 11)
    {
      setMsgErroCampo("CPF")
      toggleModal();
      return;
    }
    else{
      var x = cpf.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{3})(\d{2})/);
      cpf = x[1] + '.' + x[2] + '.' + x[3]+ '-' + x[4];
    }
    setCpf(cpf);
  }

  let isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  let comparaSenhas =() => {
    setMessageSenha("");
    if(password !== passwordConfirma )
    {
      setMessageSenha("As senhas não conferem !!!");
      setPasswordConfirma("");
    }
    else
    {
      setMessageSenha("");
    }
  };

  let montaListaUfs = () => {
    getUfs()
      .then(result => {
        if (result) {
          const ufs = result;
          setListaDeUfs(ufs);            
        }
      })
  }

  let handleSelectCidadeChange = (e) => {
    const id = e;
    setCidadeSelecionada(id);
    console.log(cidadeSelecionada);
  }

  let handleSelectUfChange = (e) => {
    console.log("Uf=> "+e);
    setUfSelecionada(e);
    getCidadesPorUf(e)
      .then(result => {
      if (result) {
        const cidades = result;
        setListaDeCidades(cidades);            
      }
    });
  }

  let getColaborador = () => {
    usuarioModel.idPessoa = 0;
    usuarioModel.idUsuario = 0;
    usuarioModel.userName = username;
    usuarioModel.dataAtualizacao = "";
    usuarioModel.dataCadastro = "";
    usuarioModel.dataValidade = "";
    usuarioModel.celular = celular;
    usuarioModel.nome = nome;
    usuarioModel.password = password;
    usuarioModel.perfil = cpf.length == 11 ? "PF": "PJ";
    usuarioModel.plano = plano;
    usuarioModel.primeiroAcesso = "S";
    usuarioModel.token =  "";
    usuarioModel.token_type = "";
    usuarioModel.telefone =  telefone;
    usuarioModel.cpf = cpf;
    usuarioModel.idCidade = cidadeSelecionada
    //console.log(JSON.stringify(usuarioModel));
    return usuarioModel;
  }

  let limparDados = () => {
    setNome("");
    setUserName("");
    setPassword("");
    setPasswordConfirma("");
    setCelular("");
    setTelefone("");
    setCpf("");
    setPlano("");
    setMessage("");
    setCor("");
  }

  const validarForcaSenha = (pwd) => {

    if (pwd != null) {
      const fSenha = ForcaSenha(pwd);
      const bufferValue = fSenha;
      const textoForcaSenha = '';

      if (fSenha <= 40) {
        setMessage('A senha não atende os requisitos necessários!');
        setCor("error");
        setPwdValida(false);          
        setForcaPwd(fSenha);
      }

      if (fSenha > 40 && fSenha <= 70) {
        
        setMessage('A senha ainda NÃO atende aos requisitos necessários!');
        setPwdValida(false);
        setCor("warning");
        setForcaPwd(fSenha);
      }

      if (fSenha > 70 && fSenha <= 90) {
        setMessage('Sua senha é fraca, mas ATENDE aos requisitos necessários!');
        setPwdValida(true);
        setCor("primary");
        setForcaPwd(fSenha);
      }

      if (fSenha > 90) {
        setMessage('Senha forte! ATENDE aos requisitos necessários!');
        setPwdValida(true);
        setCor("success");
        setForcaPwd(100);
      }
    } else {
      setMessage("");
      setCor("");
    }  
  }

  const focusSenhasHandler = () => {
    setMessageSenha("");
  }

  const handlePasswordChange = (e) =>{
    setPassword(e);
    validarForcaSenha(e);
  }

  const focusHandler = () => {
    setMessageEmail("");
  }

  const blurHandler = () => {
    validaUsername(username);        
  }

  let validaUsername = (username) => {
    if (username !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(username)) {        
        setMessageEmail("ATENÇÃO!! Este não parece se um e-mail válido.");
        setUserName("");
        return false;
      }
      else
      {
        setUserName(username);
        return true;
      }
    }
  }
   
  return (
    <>
    <BaseLayout
      title="Cadastro de novo usuário"
      breadcrumb={[{ label: "home", route: "/" }, { label: "Cadastro de novo usuário" }]}
    >      
      <MKBox px={0} width="100%" height="auto" mx="auto" position="relative" zIndex={2} mb={5}>
        <Grid container spacing={0} justifyContent="center" alignItems="center" mt={2}>

          <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
              <MKBox pt={4} pb={3} px={3} borderRadius="sm" sx={{ backgroundColor: `rgb(241,241,241)` }}>

              <Grid container spacing={2}>
                <Grid container sapcing={2} justifyContent="center" alignItems="center">
                  <MKTypography variant="h5" mb={0} sx={{textAlign: `center`}}>Cadastre-se no zerados.com.br</MKTypography>
                </Grid>
                <Grid container sapcing={2} justifyContent="center" alignItems="center">
                  <MKTypography variant="h6" mb={2} sx={{textAlign: `center`}}>A plataforma do carro que mais cresce em Minas</MKTypography>
                </Grid>
              </Grid>

              <Grid container spacing={0}>
                <Grid item xs={12} xl={6}>
                  <img src={bgImage} alt="zerados" title="zerados" className="rounded img-fluid" />
                </Grid>

              <Grid item xs={12} xl={6}>
                <MKBox
                  bgColor="white"
                  borderRadius="md"
                  sx={{ backgroundColor: `rgb(241,241,241)` }}
                >
                  <MKBox p={3}>
                    <MKTypography variant="body2" color="text" mb={3}>
                      Para anunciar os seus veículos em nosso serviço, faça o seu cadastro e escolha o plano que melhor
                      atenda a sua necessidade.
                    </MKTypography>
                    <form onSubmit={handleSubmit}>
                    <MKBox width="100%">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="plano">Planos</InputLabel>
                            <Select labelId="plano" id="planos" value={plano} onChange={(e) => setPlano(e.target.value)} label="Plano" required>
                              <MenuItem value="0"><em>Lançamento</em></MenuItem>
                              <MenuItem value="1">Plano 1.0</MenuItem>
                              <MenuItem value="2">Plano 1.2</MenuItem>
                              <MenuItem value="3">Plano 1.4</MenuItem>
                              <MenuItem value="4">Plano 2.0 Turbo</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            placeholderid="___.___.___-__"
                            variant="standard"
                            label="CPF / CNPJ"
                            InputLabelProps={{ shrink: true }}
                            fullWidth 
                            value={cpf} 
                            required
                            onChange={(e) => setCpf(e.target.value)}
                            onBlur={(e) => formataCpf(e.target.value)}
                            // onBlur={ blurCpfHandler }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            variant="standard"
                            label="Nome completo"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={nome} 
                            onChange={(e) => setNome(e.target.value)}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            type="email"
                            variant="standard"
                            label="Email"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={username} 
                            onChange={(e) => setUserName(e.target.value)}
                            onFocus={ focusHandler}
                            onBlur={ blurHandler }
                            required
                          />
                          { messageEmail ?
                                <div className=" c0l-6 text-center ">
                                <MKTypography variant="button" color="danger" mb={3}> {messageEmail ? messageEmail : null} </MKTypography>
                                </div>
                                : ""
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            type="password"
                            variant="standard"
                            label="Senha"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={password} 
                            onChange={(e) => handlePasswordChange(e.target.value)} 
                            min={8}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            id="confirmaPassword"
                            type="password"
                            variant="standard"
                            label="Confirme a senha"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={passwordConfirma} 
                            onChange={(e) => setPasswordConfirma(e.target.value)}
                            onBlur={comparaSenhas}
                            onFocus={focusSenhasHandler}
                            min={8}
                            required
                          />
                          { messageSenha ?
                                <div className=" c0l-6 text-center ">
                                <MKTypography variant="button" color="danger" mb={3}> {messageSenha ? messageSenha : null} </MKTypography>
                                </div>
                                : ""
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            placeholder="(__) _____-____"
                            value={celular}
                            type="tel"
                            variant="standard"
                            label="Celular"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={(e) => setCelular(e.target.value)}
                            onBlur={(e) => formataCelular(e.target.value)}
                            //onFocus={(e) => formataCelular(e.target.value)}
                            required
                          />
                        </Grid>                  
                        <Grid item xs={12} md={6}>
                          <MKInput
                            placeholder="(__) ____-____"
                            value={telefone}
                            type="tel"
                            variant="standard"
                            label="Telefone"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={(e) => setTelefone(e.target.value)}
                            onBlur={(e) => formataTelefone(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="uf">Uf</InputLabel>
                            <Select labelId="uf" id="ufs" value={ufSelecionada} onChange={(e) => handleSelectUfChange(e.target.value)} 
                              label="UF" required>
                              <MenuItem value=""><em>selecione a UF</em></MenuItem>
                              { listaDeUfs.map((uf) => (<MenuItem key={uf.codUf} value={uf.codUf}>{uf.descricao}</MenuItem>))} 
                            </Select>
                          </FormControl>

                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="uf">Cidade</InputLabel>
                            <Select labelId="cidade" id="cidades" value={cidadeSelecionada} onChange={(e) => handleSelectCidadeChange(e.target.value)} 
                              label="Cidade" required>
                              <MenuItem value=""><em>selecione a Cidade</em></MenuItem>
                              { listaDeCidades.map((cidade) => (<MenuItem key={cidade.idCidade} value={cidade.idCidade}>{cidade.nome}</MenuItem>))} 
                            </Select>
                          </FormControl>                    
                        </Grid>

                      </Grid>
                      <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>

                        <Stack spacing={1} width="100%">
                          <MKProgress color={cor} value={forcaPwd} />
                        </Stack>
                              { message ?
                                <MKTypography variant="button" color={cor} mb={3}> {message ? message : null} </MKTypography>
                                : ""
                              }
                        <MKTypography variant="button" color="text" mb={3}>
                          Sua senha deve ter no mínimo 8 caracter, ser composta por números, letras e ter pelo 
                          menos uma Letra maiúscula e um carcter escpecial (!@#$%¨&*)
                        </MKTypography>
                        
                        <MKButton type="submit" variant="gradient" color="primary">
                          Cadastrar
                        </MKButton>&nbsp;
                        <MKButton type="button" variant="gradient" color="success" onClick={ limparDados }>
                          Limpar
                        </MKButton>
                      </Grid>
                    </MKBox>
                    </form>
                  </MKBox>
                </MKBox>
              </Grid>
      </Grid>
      </MKBox>
      </Grid>
      </Grid>
      </MKBox>
      
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={show} timeout={500}>
          <MKBox
            position="relative"
            width="450px"
            display="flex"
            flexDirection="column"
            borderRadius="md"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">ATENÇÃO</MKTypography>
              {/* <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} /> */}
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
                <MKTypography variant="body2" color="text" fontWeight="regular">
                    O valor informado não parece ser um valor válido para o campo <span><i>{msgErroCampo}</i></span> .
                </MKTypography> 
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="danger" onClick={toggleModal}>
                fechar
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>

  </BaseLayout>
    </>
  );
}

export default Register;
