import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import MKBox from "components/MKBox";

//import DefaultReviewCard from "auxiliares/Cards/ReviewCards/DefaultReviewCard";
import PostCard from "auxiliares/Cards/ReviewCards/PostCard";
//import { Post } from '../../models/Post.model';
import { getNovidades } from "services/BuscaService";

function Novidades() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchPost = async () => {
      getNovidades()
        .then((result) => {
          if (result) {
            setPosts(result);
          }
        })
        .catch();
    };
    fetchPost();
  }, []);
  return (
    <MKBox component="section" py={2}>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {posts?.map((post) => {
            return (
              <Grid key={post.idPost} item xs={12} md={6} lg={4}>
                <PostCard
                  image={post.imagem}
                  titulo={post.titulo}
                  date={post.dataCadastro}
                  review={post.descricao}
                  link={post.urlOriginal}
                  fonte={post.fonte}
                />
              </Grid>
            );
          })}
        </Grid>
        <Divider sx={{ my: 1 }} />
    </MKBox>
  );
}

export default Novidades;
