import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
//import MuiLink from "@mui/material/Link";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import BaseLayout from "layouts/sections/components/BaseLayout";
import SimpleFooter from "auxiliares/Footers/SimpleFooter";

import { solicitaRecuperaSenha } from "services/LoginService"; 
import bgImage from "assets/images/zerados002pb.jpg";
import routes from "routes";


function MinhaSenha(){
    const [ userName, setUserName ] = useState("");
    const navigate = useNavigate ();
    const [ message, setMessage] = useState("");
    const [ messageIni, setMessageIni] = useState("Informe o seu login no campo acima. Mandaremos uma mensagem com as instruções para troca de senha.");

    let handleSubmit = async (e) => {  
        e.preventDefault();
        if(userName === "" || userName === null){
            setMessage("Você deve informar um email válido !");
            return;
        }
        
        if(!validaUsername(userName)){
            return;
        }
        
        try{
          solicitaRecuperaSenha(encodeURIComponent(userName));
          setMessage("Uma mensagem foi enviada para o email informado. Acesse sua caixa postal e siga as instruções para alteração da senha. Caso não veja a mensagem em sua caixa de entrada, verifique as pastas de SPAN.");
        }
        catch (err) {
          setMessage("Algo ruim aconteceu!!!");
          alert(err);
          console.log(err);
        }
    }

    let validaUsername = (userName) => {
        if (userName !== "" && userName !== null) {
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(userName)) {        
            setMessage("ATENÇÃO!! Este não parece se um e-mail válido.");
            setUserName("");
            return false;
          }
          else
          {
            setUserName(userName);
            return true;
          }
        }
      }

    return (
        <>
        <BaseLayout
            title=""
            breadcrumb={[{ label: "home", route: "/" }, { label: "Sign In" }]}
        >
        <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%" mt={-10}>
          <Grid item xs={11} sm={9} md={8} lg={8} xl={6}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="dark"
                borderRadius="xs"
                coloredShadow="primary"
                mx={0}
                mt={-5}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="text" mt={1} >
                  Esqueceu sua senha?
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3} borderRadius="md" sx={{ backgroundColor: `rgb(241,241,241)` }}>
                <MKBox component="form" role="form" onSubmit={handleSubmit}>
                  <MKBox mb={2}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="login"
                      fullWidth
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      onBlur={(e) => setMessage("")}
                    />
                  </MKBox>
                  <MKBox mt={4} mb={1} textAlign="center">
                    <MKButton
                      type="submit"
                      variant="gradient"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Recuperar 
                    </MKButton>
                    <br />
                    { message ?
                    <MKTypography
                        variant="button"
                        color="danger"
                        fontWeight="light"
                        textGradient
                        mt={1}
                    >
                      {message ? message : null}
                    </MKTypography>
                    : 
                    <MKTypography
                        variant="button"
                        color="text"
                        fontWeight="light"
                        textGradient
                        mt={1}
                    >
                     {messageIni}
                    </MKTypography>
                    }

                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
            </Grid>
        </MKBox>
        </BaseLayout>
        </>
    )

}

export default MinhaSenha;