import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from '@mui/material/InputLabel';
import MKInput from "components/MKInput";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { sendMessage } from "services/AnonimoService"

function PropostaModal({idref}) {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const [ message, setMessage ] = useState();
  const [ formData, setFormData] = useState({
    mailto: "",
    mailSubject: "Porposta - Zerados",
    assunto: "Porposta - Zerados",
    nome: "",
    email: "",
    phone: "",
    mensagem: "",
    template: "proposta",
    idReferencia: idref,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendMessage(formData)
    .then(result => { 
      if (result) {
        setMessage("Mensagem enviada com sucesso!!!");
      }
      else
      {
        setMessage("Não foi possível enviar sua mensagem. Preencha todos os campos do formulário");
      }
    })
  };


  return (
    <MKBox component="section" py={1}>
      <Container>
        <Grid container item xs={12} md={12} justifyContent="center" mx="auto">
          <MKButton variant="gradient" color="success" onClick={toggleModal}>
            Proposta
          </MKButton>
        </Grid>
        <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={show} timeout={500}>
            <MKBox
              position="relative"
              display="flex"
              flexDirection="column"
              borderRadius="md"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Proposta ref anúncio {idref} </MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
                <MKBox p={2}> 
                    <MKBox width="100%" component="form" method="post" autoComplete="off">              
                        <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <MKInput required
                            variant="standard"
                            label="Nome"
                            name="nome"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <MKInput required
                            type="email"
                            variant="standard"
                            label="e-mail"
                            name="email"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <MKInput required
                            type="phone"
                            variant="standard"
                            label="Telefone"
                            name="phone"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MKInput required
                            variant="standard"
                            label="Mensagem"
                            placeholder="Escreva a sua proposta..."
                            name="mensagem"
                            InputLabelProps={{ shrink: true }}
                            multiline
                            fullWidth
                            rows={6}
                            maxLength={10}
                            onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                        {message ?
                        <MKTypography variant="caption" color="warning" mb={3}>                    
                            {message}
                        </MKTypography>
                        : ""
                        }
                    </Grid>
                </MKBox>

            </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox display="flex" justifyContent="space-between" p={0.9}>
                    <MKButton type="submit" variant="gradient" color="success" onClick={handleSubmit}>
                        Enviar Proposta
                    </MKButton>&nbsp;
                    <MKButton variant="gradient" color="info" onClick={toggleModal}>
                        Fechar
                    </MKButton>
                </MKBox>
            </MKBox>
          </Slide>
        </Modal>
      </Container>
    </MKBox>
  );
}

export default PropostaModal;
