import * as React from 'react';
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorder from '@mui/icons-material/StarBorder';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { sendMessage } from "services/AnonimoService"
import PropostaModal from "./propostaModal";
import carImg from "assets/images/car/car.png";

function DetalheAnuncioCard({dados, color, image, marca, modelo, modeloMarca, valor, vistoriado, path, combustivel, cor, idrf, opcionais, imgs}) {
  const [ message, setMessage ] = useState();
  const [imagem, setImagem] = useState();
  const [ referencia, setReferencia ] = useState();
  const [ formData, setFormData] = useState({
    mailto: "",
    mailSubject: "Porposta - Zerados",
    assunto: "Porposta - Zerados",
    nome: "",
    email: "",
    phone: "",
    mensagem: "",
    template: "proposta",
    idReferencia: idrf,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendMessage(formData)
    .then(result => { 
      if (result) {
        setMessage("Mensagem enviada com sucesso!!!");
      }
      else
      {
        setMessage("Não foi possível enviar sua mensagem.");
      }
    })
  };

  let trocaImagem = (imgPath) => {
    setImagem(imgPath);
  }

  useEffect(() => {    
    if(path == 'P'){
      path = carImg;
    }
    setImagem(path);
    setReferencia(idrf);
  }, []);

  return (
    <MKBox
      variant={color === "transparent" ? "contained" : "gradient"}
      bgColor={color}
      borderRadius="md"
      shadow={color === "transparent" ? "none" : "md"}
      p={3}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <img
            src={imagem}
            style={{maxWidth: "100%", height: "auto" }}
            className="rounded fluid"
          />

            <Grid item  xs={12} md={6} mt={2}>
                <div>
                  <ImageList cols={6}>
                    {imgs.map((item, idx) => (
                      <ImageListItem key={idx}>
                        <img src={item.path} alt={item.path} width={100} className="rounded fluid" onClick={(e) => trocaImagem(item.path)}/>
                        {/* <ImageListItemBar primaryTypographyProps={{fontSize: '12px'}}
                          //title={item.nome}
                          actionIcon={
                            <IconButton aria-label={`star ${item.nome}`}>
                              <StarBorder  />
                            </IconButton>
                          }
                        /> */}
                      </ImageListItem>
                    ))}
                  </ImageList>
                </div>
                </Grid>

        </Grid>
        <Grid item  xs={12} md={6}>
          <MKBox lineHeight={1} mt={2}>
            <MKTypography
              display="block"
              variant={image ? "button" : "h3"}
              fontWeight="bold"
              //color={color === "transparent" || color === "light" ? "dark" : "white"}
              color={"primary"}
              mb={0.5}
              mt={1}
            >
              {marca} - {modelo} - {modeloMarca}
            </MKTypography>
            <MKTypography
              display="block"
              fontWeight="regular"
              lineHeight={1}
              color={"text"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {cor} - {combustivel}
            </MKTypography>
            <MKTypography
              variant={image ? "caption" : "button"}
              fontWeight="regular"
              lineHeight={2}
              color={"success"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Icon>money</Icon>&nbsp;
              <strong>{valor}</strong>
            </MKTypography>      
            <MKTypography
              variant={image ? "caption" : "button"}
              fontWeight="regular"
              color={"text"}
              my={1}
            >
              <i>Referência:</i> {idrf} <i>Vistoriado:</i> {vistoriado === "N" ? "Não" : "Sim"} 
              <br />
              <i>Placa:</i> {dados.placa.substr(0,3)}***{dados.placa.substr(-1,1)}
            </MKTypography>
            <br />
            <MKTypography
              variant={image ? "caption" : "button"}
              fontWeight="regular"
              color={"text"}
              my={1}
              mt={2}
              mb={2}
            >
               {dados.descritivo}
            </MKTypography>

            <MKTypography mt={2} mb={1}
              variant={image ? "caption" : "button"}
              fontWeight="regular"
              lineHeight={1}
              color={"text"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <strong>Opcionais e acessórios</strong>
            </MKTypography>        
            <List mt={2}>  
              {                   
                opcionais.map((opcional, idx) => {
                  return (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <Check />
                      </ListItemIcon>
                      <ListItemText 
                        primaryTypographyProps={{fontSize: '12px', minWidth: '30px'}}
                        primary={opcional.descricao}
                      />
                    </ListItem>
                  )
                })
              }
            </List>

            <br />
            <PropostaModal idref={idrf} />

          </MKBox>
        </Grid>
    </Grid>

    </MKBox>
  );
}

DetalheAnuncioCard.defaultProps = {
  color: "transparent",
  image: "",
  link:"",
};

DetalheAnuncioCard.propTypes = {
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "danger",
  ]),
  image: PropTypes.string,
  marca: PropTypes.string.isRequired,
  modelo: PropTypes.string.isRequired,
  modeloMarca: PropTypes.string.isRequired,
  valor: PropTypes.string.isRequired,
  vistoriado: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  combustivel: PropTypes.string.isRequired,
  cor: PropTypes.string.isRequired,
  idrf: PropTypes.string.isRequired,
  opcionais: PropTypes.array,
  imgs: PropTypes.array,
  dados: PropTypes.any,
};

export default DetalheAnuncioCard;
