import axios, { AxiosRequestConfig } from "axios";

const token = localStorage.getItem("token") || 0;
//console.log("TK => " + token);

const headers = {
  "Accept": "application/json",
  "Content-Type": "application/json",
  "Authorization": "Bearer " + token
}

const http = axios.create({
  //baseURL: "https://localhost:44306/",
  baseURL: "https://zeradoswprod.azurewebsites.net/",
});

http.interceptors.request.use(async (config: AxiosRequestConfig) => {
  config.headers = headers;
  // config.headers["Accept"] = "application/json";
  // config.headers["Content-Type"] = "application/json";
  // config.headers["Authorization"] = "Bearer " + token;
  // console.log("config axios", config);
  return config;
});

export default http;
