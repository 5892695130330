import * as React from 'react';
import { useState, useEffect } from "react";
//import { Navigate } from "react";
import Grid from "@mui/material/Grid";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from 'react-router-dom';

import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { AccessAlarm, Newspaper, ViewHeadline, Ballot } from '@mui/icons-material';

import AnuncioModal from "pages/Cadastro/Anuncio/NovoAnuncio/components/AnuncioModal";
import BaseLayout from 'layouts/sections/components/BaseLayout';
import { getCaracteristicasModelo, getDadosAnuncio, gravaNovoAnuncio, uploadImagem, excluirImagem, ListarImagens } from "services/AnuncioService"
import { gravaNovoModelo } from "services/Cadastro";  
import imgQrcode from "assets/images/qrcode/qrcode.png";

function NovoAnuncio() {
    //const classes = useStyles();

    const [habilitaImg, setHabilitaImg ] = useState(false);
    const [show, setShow] = useState(false);
    const toggleModal = () => setShow(!show);
    const [values, setValues] = React.useState({
      password: "",
      showPassword: false
    });
    const [ modeloMarca ] = useState({});
    const [modeloVM, setModeloVM] = useState({});
    const [descricaoModelo, setDescricaoModelo] = useState("");
    const [portas, setPortas ] = useState(0);
    const [cambio, setCambio ] = useState("");
    const [segmento, setSegmento] = useState("");
    const [ combustivel, setCombustivel ] = useState("");

    const [message, setMessage] = useState("");
    const [messageEmail, setMessageEmail] = useState("");
    const [messageSenha, setMessageSenha] = useState("");
    const [ msgErroCampo, setMsgErroCampo ] = useState("");
    const [ cor, setCor ] = useState("light");
//definição do anúncio
    const [ anuncioModel, setAnuncioModel ] = useState({});
    const [ loading, setLoading ] = useState(false); 
    const [ listaDeVeiculos , setListaDeVeiculos ] = useState([]); 
    const [ listaDeMarcas , setListaDeMarcas ] = useState([]); 
    const [ listaDeModelosPorMarca , setListaDeModelosPorMarca ] = useState([]); 
    const [ listaDeModelosMarca , setListaDeModelosMarca ] = useState([]);
    const [ listaDeOpcionais , setListaDeOpcionais ] = useState([]);
    const [ imagem, setImagem] = useState({});
    const [ listaDeModelos , setListaDeModelos ] = useState([]); 
    const [ dadosAnuncio , setDadosAnuncio ] = useState();
    const [ veiculoSelecionado, setVeiculoSelecionado ] = useState("");
    const [ categoriaSelecionada, setCategoriaSelecionada ] = useState("");
    const [ marcaSelecionada, setMarcaSelecionada ] = useState("");
    const [ modeloSelecionado, setModeloSelecionado ] = useState("");
    const [ modeloMarcaSelecionado, setModeloMarcaSelecionado ] = useState("");
    const [ idVeiculo, setIdVeiculo ] = useState("");
    const [ idCategoria, setIdCategoria ] = useState("");
    const [ idModelo, setIdModelo ] = useState("");
    const [ status, setStatus ] = useState("");
    const [ moeda, setMoeda ] = useState("");
    const [ valor, setValor ] = useState("");
    const [ valorAnuncio, setValorAnuncio] = useState("");
    const [ financiado, setFinanciado ] = useState("");
    const [ anoModelo, setAnoModelo ] = useState("");
    const [ anoFabricacao, setAnoFabricacao ] = useState("");
    const [ placa, setPlaca ] = useState("");
    const [ km, setKm ] = useState("");
    const [ ipvaPago, setIpvaPago ] = useState("");
    const [ corVeiculo, setCorVeiculo] = useState("");
    const [ classificacao, setClassificacao] = useState("");
    const [ dataPublicacao, setDataPublicacao] = useState("");
    const [ dataValidade, SetDataValidade ] = useState("");
    const [ idUsuario, setIdUsusario ] = useState("");
    const [ vistoriado, setVistoriado ] = useState("");
    const [ descritivo, setDescritivo ] = useState("");
    const [ usrLogado, setUsrLogado ] = useState({});
    const [ listaDeOpcionaisSelecionados , setListaDeOpcionaisSelecionados ] = useState([]);
    const [ idNovoAnuncio, setIdNovoAnuncio ] = useState(0);

    const [activeTab, setActiveTab] = useState(0);
    const handleTabType = (event, newValue) => {
      if(newValue == 2 && habilitaImg == false){
        alert("Você precisa cadastrar o anúncio antes de cadastrar as imagens...");
        return;
      }
      else if(newValue == 3 && habilitaImg == false){
        alert("Você precisa cadastrar o anúncio antes de cadastrar as imagens...");
        return;
      }
      else
      {
        setActiveTab(newValue);
      }
    }
    
    const handleSelectCategoriaChange = (event) => {
      setCategoriaSelecionada(event.target.value);
    };

    const handleSelectVeiculoChange = (event) => {
      setVeiculoSelecionado(event.target.value);
    };

    const handleSelectMarcaChange = (event) => {
      setMarcaSelecionada(event.target.value);
      setListaDeModelosPorMarca([]);
      setListaDeModelosPorMarca(listaDeModelosMarca.filter(x => x.idMarca == event.target.value));
    };

    const handleSelectModeloMarcaChange = (event) => {
      setModeloMarcaSelecionado(event.target.value);
      setListaDeModelos([]);
      //setLoading(true);
      getListaDeCaracteristicasModelos(event.target.value);
      //setLoading(false);  
    };

    const handleSelectModeloChange = (event) => {
      setModeloSelecionado(event.target.value);
    };

    const handleSelectOpcional = (event) => {
      const obj = { idOpcional: parseInt(event.target.value), descricao: "x", status: "x" };  
      const isChecked = event.target.checked;
      if (isChecked) {
        setListaDeOpcionaisSelecionados([...listaDeOpcionaisSelecionados, obj]);
      } 
      else
      {
        const filteredList = listaDeOpcionaisSelecionados.filter((item)=> item.idOpcional != obj.idOpcional );
        setListaDeOpcionaisSelecionados(filteredList);
      }      
    };  

    const handleMoedaChange = (event) => {
      setMoeda(event.target.value);
    };

    let setHandleValor = async (event) => {
      //const x = e.formatMoney(parseFloat(e) / 100, "$ ");
      const vrTraduzido = event.replace(".","").replace(",","").replace("R$ ","");
      const vr = Intl.NumberFormat('pt-BR',{style: 'currency', currency: 'BRL', }).format(event);
      setValor(vr);
      setValorAnuncio(vrTraduzido);
    };

    const handleIpvaPagoChange = (event) => {
      setIpvaPago(event.target.value);
    };

    const handleFinanciadoChange = (event) => {
      setFinanciado(event.target.value);
    };

    const handleVistoriadoChange = (event) => {
      setVistoriado(event.target.value);
    };

    const handleNovoModelo = () => {
      montaNovoModelo();
      gravaNovoModelo(modeloVM)
      .then(dados => {
        //setLoading(false);
        getListaDeCaracteristicasModelos(parseInt(modeloMarcaSelecionado));  
      }
      ).catch();
    }

    let montaNovoModelo =() => {
      modeloVM.descricao = descricaoModelo;
      modeloVM.idModeloMarca = parseInt(modeloMarcaSelecionado);
      modeloVM.idVeiculo = parseInt(veiculoSelecionado);
      modeloVM.idCarroceria = parseInt(segmento);
      modeloVM.idCambio = parseInt(cambio); 
      modeloVM.idCombustivel = parseInt(combustivel); 
      modeloVM.portas = parseInt(portas);
      return modeloVM;
    }

    const handleUploadFile = async (e) => {
      let fileList = "";
      fileList = e.target.files;      
      for (let i = 0; i < fileList.length; i++) {
        setImagem("");
        const file = fileList[i];
        //alert(file.split('.').pop())
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
           let foto = "";
           foto = reader.result;
           montaImagem(foto.toString());
           //console.log(imagem);
           //console.log("Id => ",idNovoAnuncio);
           setImagem(JSON.stringify(foto))
           uploadImagem(idNovoAnuncio,imagem);          
         };
         reader.onerror = function (error) {
           console.log('Error: ', error);
         };
      }
    }    

    let montaImagem = (foto) => {
      setImagem({});
      imagem.dados = foto;
    }


    let montaAnuncio = () => {
      anuncioModel.idAnuncio = 0;
      anuncioModel.idCategoria = parseInt(categoriaSelecionada);
      anuncioModel.idModelo = parseInt(modeloSelecionado);
      anuncioModel.idUsuario = parseInt(usrLogado.idUsuario);
      anuncioModel.idVeiculo = parseInt(veiculoSelecionado);
      anuncioModel.status = "A";
      anuncioModel.financiado = financiado;
      anuncioModel.anoModelo = parseInt(anoModelo);
      anuncioModel.anoFabricacao = parseInt(anoFabricacao);
      anuncioModel.classificacao = 0;
      anuncioModel.cor = corVeiculo;
      anuncioModel.dataPublicacao = "";
      anuncioModel.dataValidade = "";
      anuncioModel.descritivo = descritivo;
      anuncioModel.ipvaPago = ipvaPago;
      anuncioModel.km = parseInt(km);
      anuncioModel.moeda = moeda != null ? 'R$' :  moeda;
      anuncioModel.placa = placa;      
      anuncioModel.valor = parseFloat(valorAnuncio);
      anuncioModel.vistoriado = vistoriado;
      anuncioModel.acessorios =  [];
      anuncioModel.opcionais =  listaDeOpcionaisSelecionados;
      anuncioModel.veiculo =  null;
      anuncioModel.categoria =  null;
      anuncioModel.modelo =  null;
      anuncioModel.usuario =  null;
      return anuncioModel;
    }

    useEffect(() => {
      getToken();
      const token = getToken();
      if(token){          
        setUsrLogado(JSON.parse(localStorage.getItem("usuarioLogado")));
        getListaParaNovoAnuncio();
      }
      else
      {
        navigate("/Login");
        setMessage("Algo ruim aconteceu!!! :-(");
      }
    }, [])

    const getToken = () => localStorage.getItem('token');

    const getListaParaNovoAnuncio = () => {
      //setLoading(true);
      getDadosAnuncio().then(result => {
        if (result) {
          setListaDeVeiculos(result.veiculos);
          setListaDeMarcas(result.marcas);
          setListaDeModelosMarca(result.modelosMarca);
          setListaDeModelosPorMarca(result.modelosMarca);
          setListaDeOpcionais(result.opcionais);
        }
        else{
          const lista = null;
          const listaDeVeiculos = lista;
        }
        //setLoading(false);
      })
      .catch();        
    }

    const getListaDeCaracteristicasModelos = (id) => {
      //setLoading(true);
      getCaracteristicasModelo(id)
      .then(dados => {
        //setLoading(false);
        if (dados) {
          setListaDeModelos(dados);      
        }
        else{

        }
      }
      ).catch();         
    }

    let handleSubmit = async (e) => { 
      setMessage("");       
      e.preventDefault();
      try {
        //setLoading(true);
        //let usr = getColaborador();
        let anuncio = montaAnuncio();
        setAnuncioModel(JSON.stringify(anuncio));
        gravaNovoAnuncio(anuncio)
        .then(result => { 
          if (result) {
            console.log(result);
            setIdNovoAnuncio(parseInt(result));
            console.log(idNovoAnuncio);
            setHabilitaImg(true);
            setMessage("Anuncio gravado com sucesso!!!");
          }
          else
          {
            setMessage("Não foi possível gravar este Anúncio.");
            //setLoading(false);
          }
        })
      } catch (err) {
        console.log(err);
        setMessage("Algo ruim aconteceu!!! :-(" + err);
      }
    };

  
    const handleChange = prop => event => {
      setValues({ ...values, [prop]: event.target.value });
    };

    let limparDados = () => {

    }

    return (
      <>
      <BaseLayout
      title="Anúncios"
      breadcrumb={[{ label: "home", route: "/" }, { label: "Anúncios" }]}
      >

      <Grid alignItems="top">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          ml={{ xs: "auto", lg: 2 }}
          mr={{ xs: "auto", lg: 2 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="md"
            borderColor="dark"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 4, sm: 4, md: 4 }}
            mb={{ xs: 6, sm: 9, md: 6 }}
            mx={3}
            sx={{ backgroundColor: `rgb(241,241,241)` }}
          >
            <MKBox
              bgColor="dark"
              coloredShadow="info"
              borderRadius="xs"
              p={2}
              mx={0}
              mt={0}
              textAlign="center"
            >
              <MKTypography variant="h3" color="text">
                Novo Anúncio
              </MKTypography>
              <Grid container spacing={3} direction="row" justifyContent="center">
                <Grid item xs={12} md={2}>
                  <MKTypography variant="caption" color="text">
                    <Link to="/cadastro/listaAnuncios" className="nav-link" title="Meus anúncios"><ViewHeadline color="white"  fontSize="large" /></Link>
                  </MKTypography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <MKTypography variant="caption" color="text">
                    <Link to="/pages/painel" className="nav-link" title="Meu Painel"><Ballot  color="white"  fontSize="large"/></Link>
                  </MKTypography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <MKTypography variant="caption" color="text">
                    <Link to="/cadastro/novoAnuncio" className="nav-link" title="Novo Anuncio"><Newspaper color="white"  fontSize="large" /></Link>
                </MKTypography>
                </Grid>
              </Grid>
            </MKBox>
            <form onSubmit={handleSubmit}>
              <AppBar position="static">
              <Tabs value={activeTab} onChange={handleTabType}>
              <Tab label="Veículo" value={0} />
              <Tab label="Acessórios" value={1}/>
              <Tab label="Imagens" value={2}/>
              <Tab label="Pagamento" value={3}/>
              </Tabs>
              </AppBar>
            <MKBox p={3}>              
              <MKBox width="100%">
{/* Tab Dados Veículo */}                
              {activeTab === 0 && (                
                <Grid >
                  {/* <MKTypography color="text" variant="caption">{usrLogado.nome}-{usrLogado.idUsuario}</MKTypography> */}
                  <Grid container>
                    <Grid item xs={12} md={4}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel>Tipo Usuário</InputLabel>
                          <Select value={categoriaSelecionada} onChange={handleSelectCategoriaChange} required>
                            <MenuItem value="1" selected>Particular</MenuItem>
                            <MenuItem value="2">Agência</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel id="veiculo">Tipo Veículo</InputLabel>
                          <Select value={veiculoSelecionado} onChange={handleSelectVeiculoChange} required>
                          <MenuItem value="">... tipo de veículo ...</MenuItem>
                            { listaDeVeiculos.map((veiculo) => (<MenuItem key={veiculo.idVeiculo} value={veiculo.idVeiculo}>{veiculo.descricao}</MenuItem>))}                      
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="marca">Marca</InputLabel>                          
                          <Select  value={marcaSelecionada} onChange={handleSelectMarcaChange} required>
                            <MenuItem value="">... marca ...</MenuItem>
                            { listaDeMarcas.map((marca) => (<MenuItem key={marca.idMarca} value={marca.idMarca}>{marca.nome}</MenuItem>))}                      
                          </Select>
                        </FormControl>
                      </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={4}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel>Veículo</InputLabel>
                          <Select value={modeloMarcaSelecionado} onChange={handleSelectModeloMarcaChange} required>
                            <MenuItem value="" selected>... veiculo ...</MenuItem>
                            { listaDeModelosPorMarca.map((modeloMarca) => (<MenuItem key={modeloMarca.idModeloMarca} value={modeloMarca.idModeloMarca}>{modeloMarca.nome}</MenuItem>))}                      
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel>Modelo</InputLabel>
                          <Select value={modeloSelecionado} onChange={handleSelectModeloChange} required>
                            <MenuItem value="">... modelo ...</MenuItem>
                            { listaDeModelos.map((cmodelo) => (<MenuItem key={cmodelo.idModelo} value={cmodelo.idModelo}>{cmodelo.descricao} </MenuItem>))}          
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid>          
                      {modeloMarcaSelecionado ?
                        <AnuncioModal modelo={modeloMarcaSelecionado} idTipoVeiculo={veiculoSelecionado} />
                      : ""
                      }
                      </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel>Moeda</InputLabel>
                          <Select value={moeda} onChange={handleMoedaChange} label="Moeda" required>
                            <MenuItem value="R" selected>Real</MenuItem>
                            <MenuItem value="US">Dolar</MenuItem>
                          </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <MKInput  type="text" variant="standard" label="Valor" value={valor} 
                        onChange={(e) => setValor(e.target.value)}
                        onBlur={(e) => setHandleValor(e.target.value)}
                        fullWidth 
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <MKInput type="text" variant="standard" label="Cor" value={corVeiculo} 
                        onChange={(e) => setCorVeiculo(e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <MKInput type="text" variant="standard" label="Placa" value={placa} 
                        onChange={(e) => setPlaca(e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <MKInput type="text" variant="standard" label="KM" value={km} 
                        onChange={(e) => setKm(e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel>IPVA Pago?</InputLabel>
                          <Select value={ipvaPago} onChange={handleIpvaPagoChange} label="Ipva Pago?" required>
                            <MenuItem value="S">Sim</MenuItem>
                            <MenuItem value="N">Não</MenuItem>
                          </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                      <MKInput type="text" variant="standard" label="Ano Modelo" value={anoModelo} 
                        onChange={(e) => setAnoModelo(e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <MKInput type="text" variant="standard" label="Fabricação" value={anoFabricacao} 
                        onChange={(e) => setAnoFabricacao(e.target.value)}
                        required
                      />
                    </Grid>
 
                    <Grid item xs={12} md={1}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
                        <InputLabel>Financiado</InputLabel>
                          <Select value={financiado} onChange={handleFinanciadoChange} label="Financiado" required>
                            <MenuItem value="S" >Sim</MenuItem>
                            <MenuItem value="N">Não</MenuItem>
                          </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
                        <InputLabel>Vistoriado</InputLabel>
                          <Select value={vistoriado} onChange={handleVistoriadoChange} label="Vistoriado" required>
                            <MenuItem value="S" >Sim</MenuItem>
                            <MenuItem value="N">Não</MenuItem>
                          </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={10}>
                        <MKInput type="text" variant="standard" label="Descritivo" value={descritivo} 
                          onChange={(e) => setDescritivo(e.target.value)} multiline rows={5}  required fullWidth 
                        />
                    </Grid>
                  </Grid>
                </Grid>
              )}
{/* Tab Acessórios */}
              {activeTab === 1 && ( 

                <Grid container spacing={3}>
                  {
                    listaDeOpcionais.map((opcional, index) => {
                      return(
                        <Grid  key={index} item xs={12} md={3}>
                          <FormControlLabel control={<Checkbox value={opcional.idOpcional} sx={{ '& .MuiSvgIcon-root': { fontSize: 10 } }}
                            onChange={e => handleSelectOpcional(e)} />} label={opcional.descricao} 
                          />
                        </Grid>       
                      )}
                    )
                  }

                </Grid>

              )}

{/* Tab Imagens */}
              {activeTab === 2 && ( 

              <Grid container spacing={3}>
                <Grid item  xs={12} md={12}>
                  <MKTypography variant="h3" color="text" mb={3}>
                    Imagens
                  </MKTypography>

                  <MKTypography variant="caption" color="text" mb={3}>
                    As imagens devem corresponder ao anúncio que está sendo cadastrado. Imagens abusivas, de carter pejorativo ou
                    com direitos autorais pertencentes a outra pessoa, física ou jurídica, devidamente comprovados, poderão causar o
                    descrdencimaneto do anunciante.
                  </MKTypography>
                </Grid>

                <Grid item  xs={12} md={12}>
                  <InputLabel>Selecionar imagens</InputLabel>
                    <input type="file" onChange={handleUploadFile}  multiple/>
                </Grid>
                <Grid item  xs={12} md={1}>
                {/* <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={"Drag and drop an image here or click"}
                    onChange={(files) => console.log('Files:', files)}
                  /> */}
                </Grid>
              </Grid> 

              )}

            {/* Tab Imagens */}
            {activeTab === 3 && ( 

            <Grid container spacing={3}>
              <Grid item  xs={12} md={12}>
                <MKTypography variant="h3" color="text" mb={3}>
                  Pagamento
                </MKTypography>

                <MKTypography variant="caption" color="text" mb={3}>
                  O pagamento do seu anúncio deve ser feito por PIX.
                  O QrCode abaixo apresenta os dados referente a efetivação. Aponte a câmera do seu celular para o código abaixo e faça 
                  o pagamento.
                </MKTypography>
              </Grid>

              <Grid item  xs={12} md={12} textAlign="center">
                <img src={imgQrcode} className="rounded img-fluid" width="200"></img>
              </Grid>
              <Grid item  xs={12} md={1}>
              </Grid>
            </Grid> 

            )}              

              </MKBox>


              <MKBox>
                <Grid container spacing={3} mt={2}>
                  <Grid item xs={12} md={12}>
                    <MKButton variant="gradient" color="primary" onClick={handleSubmit} >
                      Gravar
                    </MKButton>&nbsp;
                    <MKButton variant="gradient" color="info" >
                      Limpar
                    </MKButton>
                  </Grid>
                </Grid>
              </MKBox>


            </MKBox>
            </form>


            
          </MKBox>
        </Grid>
      </Grid>


 {/** Definções para Modal **/}
  </BaseLayout>
    </>
    );

}

export default NovoAnuncio;
