import * as React from 'react';
import { useState, useEffect } from "react";
//import { Navigate } from "react";
//import { useParams } from 'react-router-dom';
import useQuery from "pages/Anuncios/components/useQuery"
import Grid from "@mui/material/Grid";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from 'react-router-dom';

import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { AccessAlarm, Newspaper, ViewHeadline, Ballot } from '@mui/icons-material';
import ClipLoader from "react-spinners/ClipLoader";

//import AnuncioModal from "pages/Cadastro/Anuncio/NovoAnuncio/components/AnuncioModal";
import BaseLayout from 'layouts/sections/components/BaseLayout';
import { getCaracteristicasModelo, getDadosAnuncio, uploadImagem, 
          excluirImagem, ListarImagens,getAnuncioPorId, atualizaAnuncio } from "services/AnuncioService"


function EditaAnuncio() {
  const query = useQuery();
  const [ anuncio, setAnuncio ] = useState({
    idAnuncio: "",
    idCategoria: "1",
    idModelo: 0,
    idUsuario:"",
    idVeiculo: 0,
    idMarca: 0,
    idModeloMarca: 0,
    status: "A",
    financiado: "N",
    anoModelo: "0",
    anoFabricacao: "0",
    classificacao: "0",
    cor: "A",
    dataPublicacao: "",
    dataValidade: "",
    descritivo: "",
    documentoAno: "0",
    ipvaPago: "1",
    km: "",
    moeda: "R$",
    placa: "",      
    valor: "",
    vistoriado: "N",
    acessorios:  "",
    opcionais:  [],
    categoria:  "",
    modelo:  "",
    usuario:  "",
    imagens: [],
  });
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");

    const [activeTab, setActiveTab] = useState(0);
    const handleTabType = (event, newValue) => {
      setActiveTab(newValue);
    }
  
    const [show, setShow] = useState(false);
    const toggleModal = () => setShow(!show);

//definição do anúncio
    const [ messgae, setMessage ] = useState("");
    const [ anuncioModel, setAnuncioModel ] = useState({}); 
    const [ listaDeVeiculos , setListaDeVeiculos ] = useState([]); 
    const [ listaDeMarcas , setListaDeMarcas ] = useState([]); 
    const [ listaDeModelosPorMarca , setListaDeModelosPorMarca ] = useState([]); 
    const [ listaDeModelosMarca , setListaDeModelosMarca ] = useState([]);
    const [ listaDeOpcionais , setListaDeOpcionais ] = useState([]);
    const [ imagem, setImagem] = useState({});
    const [ listaDeImagens, setListaDeImagens] = useState({});
    const [ listaDeModelos , setListaDeModelos ] = useState([]); 
    const [ dadosAnuncio , setDadosAnuncio ] = useState();
    const [ usrLogado, setUsrLogado ] = useState({});
    const [ listaDeOpcionaisSelecionados , setListaDeOpcionaisSelecionados ] = useState([]);
    const [formData, setFormData] = useState({
      idAnuncio: "",
      idCategoria: "",
      idModelo: "",
      idUsuario:"",
      idVeiculo: "",
      status: "",
      financiado: "",
      anoModelo: "",
      anoFabricacao: "",
      classificacao: "",
      cor: "",
      dataPublicacao: "",
      dataValidade: "",
      descritivo: "",
      ipvaPago: "",
      km: "",
      moeda: "",
      placa: "",      
      valor: "",
      vistoriado: "",
      acessorios:  "",
      opcionais:  [],
      veiculo:  "",
      categoria:  "",
      modelo:  "",
      usuario:  "",
      imagens: [],
    });

    let idAnuncio = 0;
    
    const getToken = () => localStorage.getItem('token');
    //const { id } = useParams();

    const getListaParaNovoAnuncio = async () => {
      setLoading(true);
      getDadosAnuncio().then(result => {
        if (result) {
          setListaDeVeiculos(result.veiculos);
          setListaDeMarcas(result.marcas);
          setListaDeModelosMarca(result.modelosMarca);
          setListaDeModelosPorMarca(result.modelosMarca);
          setListaDeOpcionais(result.opcionais);
          setLoading(false);
        }
        else{
          const lista = null;
          setLoading(false);
        }
      })
      .catch();        
    }

    const getAnuncio = (idAnuncio) => {
      setAnuncio();
      getAnuncioPorId(idAnuncio)
        .then((result) => {
          if (result) {
            getListaDeCaracteristicasModelos(result.anuncio.idModeloMarca);
            setAnuncio(result.anuncio);
            setFormData(result.anuncio);
            setListaDeOpcionaisSelecionados(result.opcionaisSelecionados);
            setListaDeImagens(result.imagens);
            //console.log("Opcionais => ",listaDeOpcionaisSelecionados);
          } else {
            alert("Nenhum anúncio encontrado");
          }
        })
        .catch(console.log(""));
    };

    useEffect(() => {
      setLoading(true);
      getToken();
      const token = getToken();
      if(token){ 
        
        idAnuncio = query.get("id");
        setUsrLogado(JSON.parse(localStorage.getItem("usuarioLogado")));
        
        getListaParaNovoAnuncio();
        setTimeout(() => {
          setListaDeModelosPorMarca(listaDeModelosMarca.filter(x => x.idMarca == anuncio?.idMarca));
        }, 50);

        setTimeout(() => {
          getAnuncio(idAnuncio);
        }, 500);
        
        
        setLoading(false);
      }
      else
      {
        setMessage("Algo ruim aconteceu!!! :-( Entre em contato com o Adm");
        setLoading(false);
      }
      
    }, []);
    
    const handleSelectMarcaChange = (event) => {
      setIdMarca(event.target.value);
      setListaDeModelosPorMarca([]);
      setListaDeModelosPorMarca(listaDeModelosMarca.filter(x => x.idMarca == event.target.value));
    };

    const handleModeloMarcaChange = (event) => {
      setIdModeloMarca(event.target.value);
      setListaDeModelos([]);
      getListaDeCaracteristicasModelos(event.target.value);  
    };

    const handleSelectOpcional = (event) => {
      const obj = { idOpcional: parseInt(event.target.value), descricao: "x", status: "x" };  
      const isChecked = event.target.checked;
      if (isChecked) {
        setListaDeOpcionaisSelecionados([...listaDeOpcionaisSelecionados, obj]);
        console.log("Adicionando => ", listaDeOpcionaisSelecionados);
      } 
      else
      {
        const filteredList = listaDeOpcionaisSelecionados.filter((item)=> item.idOpcional != obj.idOpcional );
        setListaDeOpcionaisSelecionados(filteredList);
      }      
    };  

    let setHandleValor = async (event) => {
      //const x = e.formatMoney(parseFloat(e) / 100, "$ ");
      const vrTraduzido = event.replace(".","").replace(",","").replace("R$ ","");
      const vr = Intl.NumberFormat('pt-BR',{style: 'currency', currency: 'BRL', }).format(event);
      setValor(vr);
      setValorAnuncio(vrTraduzido);
    };

    const handleUploadFile = async (e) => {
      let fileList = "";
      fileList = e.target.files;      
      //alert(fileList.length);

      for (let i = 0; i < fileList.length; i++) {
        setImagem("");
        const file = fileList[i];
        //alert(file.split('.').pop())
        //alert(file.fileName);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
           let foto = "";
           foto = reader.result;
           montaImagem(foto.toString());
           console.log("Img ",imagem);
           console.log("Id ",formData.idAnuncio);
           setImagem(JSON.stringify(foto))
           uploadImagem(anuncio.idAnuncio,imagem);          
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    }    

    let montaImagem = (foto) => {
      setImagem({});
      imagem.dados = foto;
    }

    const getListaDeCaracteristicasModelos = (id) => {
      getCaracteristicasModelo(id)
      .then(dados => {
        if (dados) {
          setListaDeModelos(dados);      
        }
      }
      ).catch();         
    }

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      formData.opcionais = listaDeOpcionaisSelecionados;
      console.log(formData.opcionais);
      atualizaAnuncio(formData)
      .then(result => { 
        if (result) {
          alert("Anuncio gravado com sucesso!!!");
          setMessage("Anuncio gravado com sucesso!!!");
        }
        else
        {
          alert("Não foi possível gravar este Anúncio.");
          setMessage("Não foi possível gravar este Anúncio.");
        }
      })
    };

    let limparDados = () => {

    }

    return (
      <>
      <BaseLayout
      title="Anúncios"
      breadcrumb={[{ label: "home", route: "/" }, { label: "Anúncios" }]}
      >

      <Grid alignItems="top">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          ml={{ xs: "auto", lg: 2 }}
          mr={{ xs: "auto", lg: 2 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="md"
            borderColor="dark"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 4, sm: 4, md: 4 }}
            mb={{ xs: 6, sm: 9, md: 6 }}
            mx={3}
            sx={{ backgroundColor: `rgb(241,241,241)` }}
          >
            <MKBox
              bgColor="dark"
              coloredShadow="info"
              borderRadius="xs"
              p={2}
              mx={0}
              mt={0}
              textAlign="center"
            >
              <ClipLoader
                    color={color}
                    loading={loading}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
              <MKTypography variant="h3" color="text">
                Editando Anúncio Id {anuncio ? anuncio.idAnuncio : "0"}
              </MKTypography>
              <Grid container spacing={3} direction="row" justifyContent="center">
                <Grid item xs={12} md={2}>
                  <MKTypography variant="caption" color="text">
                    <Link to="/cadastro/listaAnuncios" className="nav-link" title="Meus anúncios"><ViewHeadline color="white"  fontSize="large" /></Link>
                  </MKTypography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <MKTypography variant="caption" color="text">
                    <Link to="/pages/painel" className="nav-link" title="Meu Painel"><Ballot  color="white"  fontSize="large"/></Link>
                  </MKTypography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <MKTypography variant="caption" color="text">
                    <Link to="/cadastro/novoAnuncio" className="nav-link" title="Novo Anuncio"><Newspaper color="white"  fontSize="large" /></Link>
                </MKTypography>
                </Grid>
              </Grid>
            </MKBox>
            { anuncio ?
            <form onSubmit={handleSubmit}>
            <AppBar position="static">
            <Tabs value={activeTab} onChange={handleTabType}>
            <Tab label="Dados do Veículo" value={0} />
            <Tab label="Acessórios" value={1}/>
            <Tab label="Imagens" value={2}/>
            </Tabs>
            </AppBar>
          <MKBox p={3}>              
            <MKBox width="100%">
{/* Tab Dados Veículo */}                
            {activeTab === 0 && (                
              <Grid >
                {/* <MKTypography color="text" variant="caption">{usrLogado.nome}-{usrLogado.idUsuario}</MKTypography> */}
                <Grid container>
                  <Grid item xs={12} md={4}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                        <InputLabel>Categoria</InputLabel>
                        <Select defaultValue={anuncio.idCategoria} name="idCategoria" onChange={handleInputChange} required>
                          <MenuItem value="1" selected>Particular</MenuItem>
                          <MenuItem value="2">Agência</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                        <InputLabel id="veiculo">Tipo Veículo</InputLabel>
                        <Select defaultValue={anuncio.idVeiculo} onChange={handleInputChange} name="idVeiculo" required readOnly>
                        <MenuItem value="0">... tipo de veículo ...</MenuItem>
                          { listaDeVeiculos.map((veiculo) => (<MenuItem key={veiculo.idVeiculo} value={veiculo.idVeiculo}>{veiculo.descricao}</MenuItem>))}                      
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="marca">Marca</InputLabel>                          
                        <Select defaultValue={anuncio.idMarca} name="idMarca" onChange={handleInputChange} required readOnly>
                          <MenuItem value="0">... marca ...</MenuItem>
                          { listaDeMarcas.map((marca) => (<MenuItem key={marca.idMarca} value={marca.idMarca}>{marca.nome}</MenuItem>))}                      
                        </Select>
                      </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={4}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                        <InputLabel>Veículo</InputLabel>
                        <Select defaultValue={anuncio.idModeloMarca} name="idModeloMarca" onChange={handleInputChange} required readOnly>
                          <MenuItem value="0">... veiculo ...</MenuItem>
                          { listaDeModelosPorMarca.map((modeloMarca) => (<MenuItem key={modeloMarca.idModeloMarca} value={modeloMarca.idModeloMarca}>{modeloMarca.nome}</MenuItem>))}                      
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                        <InputLabel>Modelo</InputLabel>
                        <Select defaultValue={anuncio.idModelo} name="idModelo" onChange={handleInputChange} required readOnly>
                          <MenuItem value="0">... modelo ...</MenuItem>
                          { listaDeModelos.map((cmodelo) => (<MenuItem key={cmodelo.idModelo} value={cmodelo.idModelo}>{cmodelo.descricao} </MenuItem>))}          
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid>          
                    {/* {anuncio.idModeloMarca ?
                      <AnuncioModal modelo={anuncio.idModeloMarca} idTipoVeiculo={anuncio.idVeiculo} />
                    : ""
                    } */}
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={2}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel>Moeda</InputLabel>
                        <Select defaultValue={anuncio.moeda} name="moeda" onChange={handleInputChange} label="Moeda" required>
                          <MenuItem value="R$" selected>Real</MenuItem>
                          <MenuItem value="US$">Dolar</MenuItem>
                        </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <MKInput  type="text" name="valor" variant="standard" label="Valor" defaultValue={anuncio.valor} 
                      onChange={handleInputChange}
                      onBlur={(e) => setHandleValor(e.target.value)}
                      fullWidth 
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <MKInput type="text" name="cor" variant="standard" label="Cor" defaultValue={anuncio.cor} 
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <MKInput type="text" variant="standard" label="Placa" name="placa" defaultValue={anuncio.placa} 
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <MKInput type="text" variant="standard" label="KM" name="km" defaultValue={anuncio.km} 
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel>IPVA Pago?</InputLabel>
                        <Select defaultValue={anuncio.ipvaPago} name="ipvaPago" onChange={handleInputChange} label="IPVA Pago?" required>
                          <MenuItem value="S">Sim</MenuItem>
                          <MenuItem value="N">Não</MenuItem>
                        </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                
                <Grid container spacing={3}>
                  <Grid item xs={12} md={2}>
                    <MKInput type="text" name="anoModelo" variant="standard" label="Ano Modelo" defaultValue={anuncio.anoModelo} 
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <MKInput type="text" name="anoFabricacao" variant="standard" label="Fabricação" defaultValue={anuncio.anoFabricacao} 
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
                      <InputLabel>Financiado</InputLabel>
                        <Select defaultValue={anuncio.financiado} name="financiado" onChange={handleInputChange} label="financiado" required>
                          <MenuItem value="S">Sim</MenuItem>
                          <MenuItem value="N">Não</MenuItem>
                        </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} md={1}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
                      <InputLabel>Vistoriado</InputLabel>
                        <Select defaultValue={anuncio.vistoriado} name="vistoriado" onChange={handleInputChange} label="Vistoriado" required>
                          <MenuItem value="S" >Sim</MenuItem>
                          <MenuItem value="N">Não</MenuItem>
                        </Select>
                    </FormControl>
                  </Grid> */}
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={10}>
                      <MKInput type="text" name="descritivo" variant="standard" label="Descritivo" defaultValue={anuncio.descritivo} 
                        onChange={handleInputChange} multiline rows={5}  required fullWidth 
                      />
                  </Grid>
                </Grid>
              </Grid>
            )}
{/* Tab Acessórios */}
            {activeTab === 1 && ( 

              <Grid container spacing={3}>
                {
                  listaDeOpcionais.map((opcional, index) => {
                    return(
                      <Grid  key={index} item xs={12} md={3}>
                        <FormControlLabel control={<Checkbox value={opcional.idOpcional} sx={{ '& .MuiSvgIcon-root': { fontSize: 10 } }}
                          onChange={e => handleSelectOpcional(e)} 
                          checked={listaDeOpcionaisSelecionados.findIndex(x => x.idOpcional === opcional.idOpcional) >= 0 ? true : false } 
                          />} label={opcional.descricao} 
                        />
                      </Grid>       
                    )}
                  )
                }

              </Grid>

            )}

{/* Tab Imagens */}
            {activeTab === 2 && ( 

            <Grid container spacing={3}>
              <Grid item  xs={12} md={12}>
                <MKTypography variant="h3" color="text" mb={3}>
                  Imagens
                </MKTypography>

                <MKTypography variant="caption" color="text" mb={3}>
                  As imagens devem corresponder ao anúncio que está sendo cadastrado. Imagens abusivas, de carter pejorativo ou
                  com direitos autorais pertencentes a outra pessoa, física ou jurídica, devidamente comprovados, poderão causar o
                  descrdencimaneto do anunciante.
                </MKTypography>
              </Grid>

              <Grid item  xs={12} md={12}>
                <InputLabel>Selecionar imagens</InputLabel>
                  <input type="file" onChange={handleUploadFile}  multiple/>
              </Grid>
              <Grid item  xs={12} md={1}>
              {/* <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={"Drag and drop an image here or click"}
                  onChange={(files) => console.log('Files:', files)}
                /> */}
              </Grid>

              <Grid container sapncing={3} ml={5}>
              {
                listaDeImagens.map((img, index) => {
                return(
                    <Grid item xs={12} md={1} key={index}>
                      <MKTypography variant="button" color="text">
                        <Link to={img.path} title="Editar" target="_blank"><img  src={img.path} width="50" /></Link>
                      </MKTypography>
                    </Grid>
                  
                  )}
              )}
              </Grid>
            </Grid> 
                        

            )}
            </MKBox>
            <MKBox>
              <Grid container spacing={3} mt={2}>
                <Grid item xs={12} md={12}>
                  <MKButton variant="gradient" color="primary" onClick={handleSubmit}>
                    Gravar
                  </MKButton>&nbsp;
                  <MKButton variant="gradient" color="info" >
                    Limpar
                  </MKButton>
                </Grid>
              </Grid>
            </MKBox>


          </MKBox>
          </form>   
            : ""
            }          
          </MKBox>
        </Grid>
      </Grid>

     
  </BaseLayout>
    </>
    );

}

export default EditaAnuncio;
