import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Politic() {
  return (
    <MKBox component="section" py={12}>
        <Grid item xs={12} lg={12} justifyContent="center" mx="auto" mb={5}>
          <MKTypography variant="h3" color="text" textAlign="center" px={{ xs: 6, lg: 12 }} mt={1}>
            POLÍTICA DE PRIVACIDADE
          </MKTypography>
          <MKTypography variant="body2" color="text" textAlign="justify" px={{ xs: 3, lg: 6 }} mt={1}>
            A ZERADOS LTDA (“Zerados”), pessoa jurídica de Direito Privado, sediada em Belo Horizonte - MG, tem o compromisso 
            com a transparência, a privacidade e a segurança dos dados de titulares durante todo o ciclo de tratamento dos dados 
            pessoais. Por isto, para que você entenda melhor quais informações tratamos, esta política de privacidade (“Política de Privacidade”) 
            descreve as informações e dados pessoais que coletamos, como eles são usados, armazenados e compartilhados, bem como quais 
            são as medidas que aplicamos para mantê-los seguros.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <MKTypography component={'div'} variant="body2" color="text" textAlign="justify" px={{ xs: 3, lg: 6 }} mt={1}>
              <p>1 – TERMOS GERAIS </p>
              <p>Essa política é aplicável para todos os nossos clientes, prestadores de serviços, funcionários, ou pessoas que por alguma razão a Zerados 
              venha a ter que tratar dados como controladora.</p>
              <p>As práticas descritas nesta política de privacidade só se aplicam ao tratamento dos seus dados pessoais no Brasil e estão sujeitas às 
              leis locais aplicáveis, com destaque para a Lei nº 13.709/2018 (Lei Geral de Proteção de Dados Pessoais, ou “LGPD”) e para as regras complementares 
              que vierem a ser emitidas pela autoridade nacional de proteção de dados (“ANPD”).</p>
              <p>Os termos “Zerados”, “nós” ou “nossos”, se referem ao controlador dos seus dados pessoais, ou seja, a empresa da ZERADOS. Os termos “usuário”, 
              “você”, “seu” ou “sua”, se referem a você, nosso cliente, prestador de serviços, empregado, colaborador, ou candidato a empregado.</p>
            </MKTypography>
            <MKTypography component={'div'} variant="body2" color="text" textAlign="justify" px={{ xs: 3, lg: 6 }} mt={1}>
              <p>2 – QUAIS DADOS SÃO COLETADOS PELA ZERADOS</p>
              <p>Veja abaixo quais dados pessoais nós podemos coletar e em cada situação, divididos por categorias:<br />
                Dados pessoais informados por você:</p>
                <ul>
                  <li>Receber reclamações e comunicações dos titulares, prestar esclarecimentos e adotar providências;</li>
                  <li>Dados para elaboração de contratos, tais como nome completo, foto, CEP, endereço, número de documento válido;</li>
                  <li>Dados de contato, como telefone e e-mail.</li>
                </ul>
                <p>Dados pessoais que coletamos de terceiros:</p>
                  <ul>
                    <li>Dados cadastrais, tais como: nome, CPF, número de telefone, endereço, entre outros; e</li>
                  </ul>
                <p>Dados públicos:<br />
                Podemos coletar informações sobre você que estejam disponíveis publicamente ou que foram tornadas públicas por você:</p>
                  <ul>
                    <li>Informações sobre menções ou interações com a Zerados; e</li>
                    <li>Depoimentos referentes à Zerados postadas em perfis e páginas nas redes sociais, juntamente com seu nome e imagem.</li>
                  </ul>
                <p>Durante a análise e o monitoramento de compras ou outras transações com clientes e/ou fornecedores:</p>
                  <ul>
                    <li>Dados cadastrais;</li>
                    <li>Tipo de produto;</li>
                    <li>Quantidade;</li>
                    <li>Valor da mercadoria (unitário);</li>
                    <li>Valor total da compra ou transação;</li>
                    <li>Natureza da transação financeira;</li>
                    <li>Informações da conta bancária e outros meios utilizados;</li>
                    <li>Informações de renda.</li>
                </ul>
                <p>Dados de navegação e do dispositivo:</p>
                  <ul>
                    <li>Endereço IP do dispositivo móvel utilizado para acessar o site da Zerados;</li>
                    <li>Interações realizadas e perfil de uso do site da Zerados;</li>
                    <li>Dados técnicos, como informações de URL, de conexão de rede, do provedor, e do dispositivo;</li>
                    <li>Cookies;</li>
                    <li>Atributos do dispositivo, tais como ID, sistema operacional, navegador e modelo.</li>
                  </ul>
                <p>3 – COMO NÓS UTILIZAMOS SEUS DADOS PESSOAIS</p>
                <p>Os dados são tratados da seguinte forma:</p>
                  <ul>
                    <li>Para identificar corretamente o cliente, fornecedor, colaborador, funcionário, candidato ou visitante;</li>
                    <li>Para ocupar vaga de candidato em nosso quadro de empregados, em casos de processo seletivo;</li>
                    <li>Para prestar informações aos órgãos governamentais ou fornecer algum benefício previsto em lei ou por sindicato, em caso de 
                      empregados e colaboradores;</li>
                    <li>Para elaboração e execução de contrato, faturamento e cobrança com prestadores e fornecedores;</li>
                    <li>Para enviar os produtos adquiridos ou comunicações de ofertas;</li>
                    <li>Para entrar em contato com você, quando necessário. Esse contato pode contemplar diversos assuntos, como comunicação sobre 
                      promoções e ofertas, respostas a dúvidas, respostas de reclamações e solicitações, atualizações dos pedidos realizados e 
                      informações de entrega;</li>
                    <li>Para auxiliar no diagnóstico e solução de problemas nos produtos;</li>
                    <li>Para desenvolver novas funcionalidades e melhorias, melhorando a sua experiência com nossos serviços disponíveis;</li>
                    <li>Para consultar suas informações nas bases de dados de agências de crédito;</li>
                    <li>Para colaborar em investigações e medidas de prevenção e combate a ilícitos, fraudes, crimes financeiros e crimes de lavagem de 
                      dinheiro e/ou de financiamento ao terrorismo;</li>
                    <li>Para garantir o cumprimento de obrigação legal ou regulatória ou garantir o exercício regular de direitos da Zerados. Nesses casos, 
                      podemos, inclusive, utilizar e apresentar as informações em processos judiciais e administrativos, se necessário;</li>
                    <li>Para colaborar com o cumprimento de ordem judicial, de autoridade competente ou de órgão fiscalizador;</li>
                    <li>Dados de navegação: para auxiliar no diagnóstico e solução de problemas técnicos, bem como para desenvolver novas funcionalidades e 
                      melhorias, melhorando a sua experiência com nossos serviços disponíveis;</li>
                    <li>Para proporcionar segurança às pessoas que frequentam nossas dependências, monitoramento dos dados de controle de acesso e imagens 
                      gravadas pelo sistema de Circuito Fechado de Televisão (CFTV).</li>
                </ul>
            </MKTypography>
            <MKTypography component={'div'} variant="body2" color="text" textAlign="justify" px={{ xs: 3, lg: 6 }} mt={1}>
              <p>4 – COM QUEM COMPARTILHAMOS SEUS DADOS</p>
              <p>Podemos compartilhar seus Dados com terceiros nas seguintes hipóteses:</p>
              <p>Parceiros de negócios, prestadores de serviço e outros terceiros: para que possamos disponibilizar nossos produtos e prestar os nossos serviços com qualidade, 
                bem como a relação adequada com funcionários, colaboradores, parceiros e prestadores, contamos com a colaboração de diversos prestadores de serviço, que tratam os 
                dados pessoais coletados em nosso nome e de acordo com nossas instruções.</p>
              <p>Esses prestadores de serviço atuam principalmente para auxiliar-nos nas análises de auditoria, análises jurídicas, fornecedores de serviços de tecnologia da 
                informação, de atendimento ao consumidor, de comunicação, de serviços estatísticos, de serviços logísticos, de pesquisas, marketing, serviços financeiros e de 
                meios de pagamentos e outros terceiros.</p>
              <p>Autoridades judiciais, policiais ou governamentais e órgãos reguladores: nós devemos fornecer dados pessoais de clientes, prestadores ou funcionários, em atendimento 
                à investigações e medidas de prevenção e combate a ilícitos, fraudes, crimes e garantia da segurança dos clientes, funcionários e prestadores da Zerados; exercício 
                regular de direitos da Zerados, inclusive apresentando documentos em processos judiciais, administrativos e arbitrais, se necessário; cumprimento de ordem judicial, 
                atendimento de solicitação de autoridade competente ou órgão fiscalizador; e cumprimento de obrigação legal ou regulatória.</p>
              <p>Alguns exemplos: informações à Receita Federal e Ministério do Trabalho, realização de exame admissional em empresa de Segurança e Medicina do Trabalho, fornecimento 
                de cartão de transporte, fornecimento de cartão alimentação/refeição e plano de saúde.</p>
              <p>5 – COMO NÓS PROTEGEMOS SEUS DADOS</p>
              <p>Mapeamos os processos que tratam dados pessoais e elencamos os potenciais riscos à Privacidade e Proteção de Dados, buscando continuamente a mitigação dos riscos 
                através de treinamentos e da adoção de medidas técnicas e organizacionais aplicadas aos nossos processos e sistemas de informação.</p>
              <p>6 – DIREITOS COMO TITULAR DE DADOS PESSOAIS</p>
              <p>A transparência sobre o tratamento dos seus dados pessoais é prioridade para a Zerados. Além das informações disponibilizadas nesta Política de Privacidade, 
                você pode também exercer os direitos previstos na Lei Geral de Proteção de Dados, entre eles:</p>
                <ul>
                  <li>Direito de confirmação: você pode confirmar se a Zerados realiza o tratamento de algum dado pessoal seu;</li>
                  <li>Direito de acesso: você também pode fazer uma solicitação direcionada ao contato dpo.Zerados@Zerados.ind.br para obter detalhes de como suas informações estão 
                    sendo utilizadas e ter uma cópia das referidas informações, com prazo de entrega a ser combinado em cada caso concreto;</li>
                  <li>Direito a correção: você poderá corrigir ou remover eventuais dados que estejam incompletos, inexatos ou desatualizados;</li>
                  <li>Direito de anonimização, bloqueio ou eliminação: você poderá solicitar a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados 
                    em desconformidade com as finalidades previstas nesta Política, observando-se, para tanto, demais normas do ordenamento jurídico;</li>
                  <li>Direito de revogação do consentimento: caso você tenha dado o seu consentimento para tratamento dos seus dados pessoais, você pode solicitar a revogação 
                    desse consentimento. É importante que você saiba que alguns dados poderão ser mantidos para finalidades legais;</li>
                  <li>Direito de oposição aos tratamentos futuros para as demais bases legais: você poderá solicitar a exclusão e a interrupção de qualquer outro serviço prestado 
                    pela Zerados, mas também nesse caso é importante que você saiba que alguns dados poderão ser mantidos para finalidades legais.</li>
              </ul>
              <p>Todas as solicitações serão tratadas de forma gratuita e serão submetidas a uma prévia avaliação da sua identidade e da viabilidade do atendimento, a fim de cumprir 
                com eventuais obrigações que impeçam o completo atendimento das requisições dos titulares de direito.</p>
              <p>7 – ARMAZENAMENTO, RETENÇÃO E EXCLUSÃO DOS SEUS DADOS PESSOAIS</p>
              <p>Enquanto você for cliente, parceiro, prestador, empregado, candidato, visitante ou terceiro pelo qual nos termos expostos for necessário o tratamento de dados, 
                eles serão mantidos em ambiente seguro e controlado pela Zerados.</p>
              <p>Quando aplicável, após a rescisão do contrato ou vínculo entre você e a Zerados, do qual derivou tratamento de dados, e mesmo se você optar por excluir seus dados 
                pessoais da nossa base de cadastro, seus dados pessoais ficam armazenados por período adicional, seja para fins de auditoria, cumprimento de obrigações legais ou 
                regulatórias, para o exercício regular de direitos do Zerados ou também pelo prazo necessário de acordo com a base legal que justifique a retenção dos dados. Apenas manteremos 
                os dados estritamente necessários em nossos bancos de dados e para finalidades legalmente permitidas e legítimas.</p>
              <p>8 – COOKIES</p>
              <p>Não registramos nem armazenamos detalhes da sua atividade por meio de cookies.</p>
              <p>Agora, se você é um cliente, colaborador ou representante, ou, ainda, vai enviar seu currículo para nosso Banco de Talentos, precisamos registrar e armazenar pequenos 
                arquivos no seu computador para identificá-lo como usuário e lhe conceder acesso a informações do seu perfil e fazermos isso por meio do que chamamos de cookie.</p>
              <p>Esse cookie é acionado quando você clica em “Área Restrita”, e é modificado ao se “Desconectar” do site.</p>
              <p>Você pode se opor ao uso desses cookies, configurando o navegador para não aceitá-los, mas, sem eles, você não poderá se registrar na área restrita do site.</p>
              <p>Você pode excluir os cookies armazenados no seu dispositivo a qualquer momento.</p>
              <p><strong>Ah! A Zerados não compartilha dados com outras empresas.</strong></p>
              <p>9 – FALE CONOSCO</p>
              <p>Para esclarecer qualquer dúvida, fazer solicitações ou para maiores informações que digam respeito à Privacidade e Proteção de Dados Pessoais, fale com o nosso 
                Encarregado de Dados por meio do e-mail: dpo.zerados@zerados.ind.br.</p>
              <p>Estamos sempre à disposição para esclarecer suas dúvidas e colocar você no controle dos seus dados pessoais.</p>
              <p>Você será informado sobre as atualizações desta Política por e-mail ou aviso no nosso site.</p>
              <p>Contem conosco para mantê-los informados!</p>

            </MKTypography>

          </Grid>
          {/* <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Consulte nosso anúncios"
              description="Encontre o veículo dos seus sonhos"
              action={{
                type: "internal",
                route: "pages/anuncios",
                color: "info",
                label: "Anúncios",
              }}
            />
          </Grid> */}
        </Grid>
    </MKBox>
  );
}

export default Politic;
