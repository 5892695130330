import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";

import RotatingCard from "auxiliares/Cards/RotatingCard";
import RotatingCardFront from "auxiliares/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "auxiliares/Cards/RotatingCard/RotatingCardBack";
//import DefaultInfoCard from "auxiliares/Cards/InfoCards/DefaultInfoCard";

import bgFront from "assets/images/examples/testimonial-6-3.jpg";
import bgBack from "assets/images/examples/testimonial-6-2.jpg";

function PriceInformation() {
  return (
    <MKBox component="section" py={2} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>

            <RotatingCard>
              <RotatingCardFront
                color="light"
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Plano lançamento
                    <br />
                    Início
                  </>
                }
                description="R$0,00"
              />
              <RotatingCardBack
                color="light"
                image={bgBack}
                title="Nossos planos"
                description="Plano promocional. Seu anúncio fica disponível para consulta por 10 dias."
                action={{
                  type: "internal",
                  route: "/pages/authentication/register",
                  label: "Contrate agora",
                }}
              />
            </RotatingCard>
            </Grid>
            <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                color="success"
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Plano 1.0
                    <br />
                    Início
                  </>
                }
                description="R$130,00"
              />
              <RotatingCardBack
                color="success"
                image={bgBack}
                title="Nossos planos"
                description="Plano individual. Você paga R$129,00 e seu anúncio fica disponível para consulta por 10 dias."
                action={{
                  type: "internal",
                  route: "/pages/authentication/register",
                  label: "Contrate agora",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                color="warning"
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Plano 1.2
                    <br />
                    Início
                  </>
                }
                description="R$149,00"
              />
              <RotatingCardBack
                color="warning"
                image={bgBack}
                title="Nossos planos"
                description="Você paga R$149,00 e poderá cadastrar até 3 anúncios por mês. Seus anúncios ficarão disponíveis para consulta por 10 dias, com direito a aparecer no destaque por dois dias."
                action={{
                  type: "internal",
                  route: "/pages/authentication/register",
                  label: "Contrate agora",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                color="primary"
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Plano 1.4
                    <br />
                    standard
                  </>
                }
                description="R$159,00"
              />
              <RotatingCardBack
                color="primary"
                image={bgBack}
                title="Nossos planos"
                description="Plano múltiplo. Você paga R$159,00 e terá direito a ter 5 anúncios disponíveis para consulta por 10 dias, a partir da data de cadastramento do anúncio,
                  com direito a aparecer no destaque por dois dias."
                action={{
                  type: "internal",
                  route: "/pages/authentication/register",
                  label: "Contrate agora",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                color="dark"
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Plano 2.0
                    <br />
                    Turbo
                  </>
                }
                description="R$229,00"
              />
              <RotatingCardBack
                color="dark"
                image={bgBack}
                title="Nossos planos"
                description="Plano múltiplo. Você paga R$229,00 e terá direito a ter 10 anúncios disponíveis para consulta por 10 dias, a partir da data de cadastramento do anúncio,
                com garantia de retorno ao destaque por cinco dias."
                action={{
                  type: "internal",
                  route: "/pages/authentication/register",
                  label: "Contrate agora",
                }}
              />
            </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PriceInformation;
