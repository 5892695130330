import { useEffect, useState, CSSProperties  } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import AnuncioCard from "auxiliares/Cards/ReviewCards/AnuncioCard";

//import bgFront from "assets/images/examples/testimonial-6-3.jpg";
//import bgBack from "assets/images/examples/testimonial-6-2.jpg";
import PropagateLoader from "react-spinners/PropagateLoader";
import useQuery from "../components/useQuery";
import { getAnuncios, getAnunciosByKeywords } from "../../../services/BuscaService";

const override: CSSProperties = {
  display: "block",
  margin: "50vh auto",
  borderColor: "#1065c2",
};

function AnunciosInformation() {
  const query = useQuery();
  const [anuncios, setAnuncios] = useState([]);
  const [ imgs, setImgs ] = useState([]);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#1065c2");
  const [ message, setMessage ] = useState();
  const [ formData, setFormData] = useState({
    categoria: "",
    marca: "",
    modelo: "",
    valor: "",
    cidade: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    getAnunciosByKeywords(formData)
    .then(result => { 
      if (result) {
        setAnuncios(result);
        setMessage(result.length + " anuncios encontrados!");
      } else {
        setMessage("Nenhum anúncio encontrado...!");
      }
    })
  };

  const getListaDeAnuncios = (termo) => {
    getAnuncios(termo)
      .then((result) => {
        if (result) {
          setAnuncios(result);
        } else {
          alert("Nenhum anúncio encontrado");
        }
        setLoading(false)
      })
      .catch(setLoading(false));
  };

  useEffect(() => {
    let termo = query.get("termo");
    if (!termo || termo === " ") {
      termo = "a";
    } 
    setLoading(true);
    getListaDeAnuncios(termo);    
    //setLoading(false);
  }, []);
  //navigate("/pages/anuncios?termo=" + termoBusca);
  return (
    <MKBox component="section" py={0} mb={5}>
      <PropagateLoader
        color="#0000ff"
        cssOverride={override}
        loading={loading}
        size={15}
      />

      <MKBox py={0} mb={0} component="form" method="post" autoComplete="off">
        <Grid container spacing={0} sx={{ mt: 0 }}>
          <Grid item xs={12} md={12} lg={12}>
            <MKTypography variant="caption" mb={2} sx={{textAlign: `left`}}>
              Refine a sua busca...
            </MKTypography>
            <Grid container spacing={0} sx={{ mt: 0, backgroundColor: `#f1f1f1`, borderRadius: 2 }}>
              <Grid item xs={12} md={2} lg={2}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel>Categoria</InputLabel>
                  <Select name="categoria" value={formData.categoria} onChange={handleInputChange} style={{margin:25}}>
                    <MenuItem value="1" selected>Particular</MenuItem>
                    <MenuItem value="2">Agência</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                  <MKInput variant="standard" value={formData.marca}
                    label="Marca"
                    name="marca"
                    //InputLabelProps={{ shrink: true }}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                  <MKInput variant="standard" value={formData.modelo}
                    label="Modelo"
                    name="modelo"
                    //InputLabelProps={{ shrink: true }}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                  <MKInput variant="standard" value={formData.valor}
                    label="Valor"
                    name="valor"
                    //InputLabelProps={{ shrink: true }}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <FormControl variant="standard" sx={{ m: 1 }}>
                  <MKInput variant="standard" value={formData.cidade}
                    label="Cidade"
                    name="cidade"
                    //InputLabelProps={{ shrink: true }}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 150, marginTop:4 }}>
                <MKButton variant="gradient" color="primary" onClick={handleSubmit}>
                  Pesquisar
                </MKButton>&nbsp;
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        { message ?         
          <MKTypography variant="caption" mb={2} sx={{textAlign: `left`}}>
            {message}
          </MKTypography>         
          : ""
        }
      </MKBox>

        <Grid container spacing={1} sx={{ mt: 0 }}>
          {anuncios?.map((anuncio) => {
            return (
              <Grid key={anuncio.idAnuncio} item xs={12} md={6} lg={4}>
                <AnuncioCard
                  imagem={anuncio.image}
                  marca={anuncio.nomeMarca}
                  modelo={anuncio.nomeModelo}
                  modeloMarca={anuncio.nomeModeloMarca}
                  valor={" " + anuncio.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                  vistoriado={anuncio.vistoriado}
                  cor={anuncio.cor}
                  combustivel={anuncio.combustivel}
                  idrf={""+anuncio.idAnuncio}
                  color="light"
                  path={anuncio.imagemPadrao} 
                  sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: 0,
                    mb: 4,
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                    //backgroundColor: "rgba(255, 255, 255, .8)",
                    backdropFilter: "saturate(200%) blur(30px)",
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  }}
                  dados={anuncio}
                />
              </Grid>
            );
          })}
        </Grid>
    </MKBox>
  );
}

export default AnunciosInformation;
